import React, { useEffect, useState, useCallback } from "react";
import { useDirectus } from "../../Providers/DirectusProvider";
import Moment from "moment";
import Swal from "sweetalert2";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import { Link } from "react-router-dom";

export const Opciones = ({
    idBeneficiarioPlan,
    handleCloseLoading,
    handleToggleLoading,
    showVentasCompletas = false,
}) => {
    const { directus } = useDirectus();
    const [venta, setVenta] = useState();
    const [puedeAgregarCargas, setPuedeAgregarCargas] = useState(false);
    const [isLoading, setLoading] = useState(true);

    //LLAMADA FLOW
    const getFlow = useCallback(
        async (parametros, accion, method = "post") => {
            try {
                let resApi = null;
                if (method === "get") {
                    resApi = await directus.transport.get("/flow/" + accion);
                } else {
                    resApi = await directus.transport.post("/flow/" + accion, {
                        parametros,
                    });
                }

                return resApi.raw;
            } catch (error) {
                //levantar error y mostrarlo en template
                return error.response.raw;
            }
        },
        [directus]
    );

    const sendEmailAgain = async (dataCustomerPlan) => {
        const customerCreate = await getFlow(dataCustomerPlan, "send/email");
        return customerCreate;
    };

    const getLinkPago = async (dataCustomerPlan) => {
        const customerCreate = await getFlow(dataCustomerPlan, "get/linkpago");
        return customerCreate;
    };

    const verCertificado = useCallback((venta) => {
        Swal.fire({
            width: "auto",
            customClass: {
                title: "mas-info",
                confirmButton: "button-plan btn-principal siguiente-info",
                htmlContainer: "lista-detalle-venta",
            },
            title:
                "<strong> Certificado venta Nro " + venta.idVenta + "</strong>",
            html:
                "<table>" +
                "<tr>" +
                "<td class='font-negrita'>Asegurado</td>" +
                "<td>: " +
                venta.idBeneficiarioPlan.idBeneficiario.nombres +
                " " +
                venta.idBeneficiarioPlan.idBeneficiario.primerApellido +
                " " +
                venta.idBeneficiarioPlan.idBeneficiario.segundoApellido +
                "</td>" +
                "</tr>" +
                "<tr>" +
                "<td>Contacto</td>" +
                "<td>: " +
                venta.idBeneficiarioPlan.idBeneficiario.email +
                "</td>" +
                "</tr>" +
                "<tr>" +
                "<td>Plan</td>" +
                "<td>: " +
                venta.idBeneficiarioPlan.idPlan.nombre +
                "</td>" +
                "</tr>" +
                "<tr>" +
                "<td>Prima Mensual</td>" +
                "<td>: " +
                venta.idBeneficiarioPlan.idPlan.valor +
                "</td>" +
                "</tr>" +
                "<tr>" +
                "<td>Fecha de contratación</td>" +
                "<td>: " +
                Moment(venta.idBeneficiarioPlan.fechaEnrolamiento).format(
                    "YYYY-MM-DD"
                ) +
                "</tr>" +
                "<tr>" +
                "<td>Asegurado</td>" +
                "<td>: " +
                Moment(venta.idBeneficiarioPlan.fechaEnrolamiento).format(
                    "YYYY-MM-DD"
                ) +
                " - " +
                Moment(
                    venta.idBeneficiarioPlan.fechaVencimientoCobertura
                ).format("YYYY-MM-DD") +
                "</td>" +
                "</tr>" +
                "<tr>" +
                "<td>Vendedor</td>" +
                "<td>: " +
                venta.idVendedor.nombre +
                "</td>" +
                "</tr>" +
                "</table>",
            showCloseButton: true,
            /*focusConfirm: true,
            confirmButtonText: "Imprimir Poliza",*/
        }); /*.then((result) => {
            if (result.isConfirmed) {
                asignarPlan(plan);
            }
        })*/
        return true;
    });

    const enEspera = useCallback((venta) => {
        if (venta.estado == 2) {
            Swal.fire({
                icon: "warning",
                width: "50%",
                title: venta.comentario,
                allowOutsideClick: true,
                allowEscapeKey: true,
            }).then(async (result) => {});
        } else {
            Swal.fire({
                icon: "info",
                width: "50%",
                title: "El beneficiario aún no completa su suscripción",
                allowOutsideClick: true,
                allowEscapeKey: true,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Enviar correo nuevamente",
                denyButtonText: "Copiar link de suscripción",
                cancelButtonText: "Volver",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    handleToggleLoading();
                    //ENVIAR CORREO NUEVAMENTE
                    var dataCustomerPlan = {
                        skuPlan: venta.idBeneficiarioPlan.idPlan.sku,
                        idBeneficiario:
                            venta.idBeneficiarioPlan.idBeneficiario
                                .idBeneficiario,
                        completarPago: venta.idBeneficiarioPlan.idBeneficiario
                            .fechaNacimiento
                            ? true
                            : false,
                    };
                    try {
                        var retornoSendEmail = await sendEmailAgain(
                            dataCustomerPlan
                        );
                    } catch (error) {
                        handleCloseLoading();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Error al enviar correo",
                        });
                    }
                    if (retornoSendEmail.idBeneficiario) {
                        handleCloseLoading();

                        Swal.fire({
                            title:
                                "Se envió correo exitosamente a " +
                                venta.idBeneficiarioPlan.idBeneficiario.email,
                            icon: "success",
                        });
                    }
                } else if (result.isDenied) {
                    handleToggleLoading();
                    //ENVIAR CORREO NUEVAMENTE
                    var dataCustomerPlan = {
                        skuPlan: venta.idBeneficiarioPlan.idPlan.sku,
                        idBeneficiario:
                            venta.idBeneficiarioPlan.idBeneficiario
                                .idBeneficiario,
                        completarPago: venta.idBeneficiarioPlan.idBeneficiario
                            .fechaNacimiento
                            ? true
                            : false,
                    };
                    try {
                        var retornoLink = await getLinkPago(dataCustomerPlan);
                    } catch (error) {
                        handleCloseLoading();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Error al obtener link ",
                        });
                    }
                    if (retornoLink.linkPago) {
                        handleCloseLoading();
                        // Select the text field
                        var linkPago = retornoLink.linkPago;
                        navigator.clipboard.writeText(linkPago);
                        Swal.fire({
                            title: "Se copió link en Portapapeles",
                            html:
                                "<br><label class='small-letter'>" +
                                linkPago +
                                "</label><br>",
                            icon: "success",
                        });
                    }
                } else if (result.isDismissed) {
                    /*Swal.fire("Changes are not savedx", "", "info");*/
                }
            });
        }
    });

    //CONSULTAR VENTA
    useEffect(() => {
        async function getInfoVenta() {
            const datoVenta = await directus
                .items("venta")
                .readByQuery({
                    /*idBeneficiarioPlan.*.*.*.*.**/
                    fields: ["*,idVendedor.*, idBeneficiarioPlan.*.*.*.*"],
                    filter: {
                        idBeneficiarioPlan: idBeneficiarioPlan,
                    },
                    limit: 1,
                })
                .then(async (response) => {
                    setVenta(response.data[0]);
                    let cargas =
                        response.data[0].idBeneficiarioPlan.idBeneficiario
                            .cargas;

                    cargas.forEach((c) => {
                        if (!c.cargaCompleta) {
                            setPuedeAgregarCargas(true);
                        }
                    });
                    setLoading(false);
                });
        }
        getInfoVenta();
    }, [directus]);

    if (isLoading) {
        return <div className="App">Loading...</div>;
    }

    return (
        <div className="d-flex">
            {venta.estado == 1 ? (
                <div>
                    <IconButton
                        onClick={() => {
                            verCertificado(venta);
                        }}
                    >
                        <EmojiEventsIcon color="success" />
                    </IconButton>

                    {puedeAgregarCargas && showVentasCompletas ? (
                        <IconButton
                            component={Link}
                            to={"/agregar-cargas"}
                            state={{ dataBene: venta }}
                        >
                            <GroupAddIcon color="secondary" />
                        </IconButton>
                    ) : (
                        ""
                    )}
                </div>
            ) : venta.estado == 2 ? (
                <div>
                    <IconButton
                        onClick={() => {
                            enEspera(venta);
                        }}
                    >
                        <HourglassBottomIcon color="warning" />
                    </IconButton>
                </div>
            ) : (
                <div>
                    
                    <IconButton
                        component={Link}
                        to={"/editar-venta"}
                        state={{ dataBene: venta }}
                    >
                        <EditIcon color="primary" />
                    </IconButton>

                    <IconButton
                        onClick={() => {
                            enEspera(venta);
                        }}
                    >
                        <HourglassBottomIcon color="warning" />
                    </IconButton>
                </div>
            )}
        </div>
    );
};
