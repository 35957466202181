import { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { useAuth } from "../Hooks/useAuth";
import { useDirectus } from "../Providers/DirectusProvider";

const SessionExpirationChecker = () => {
  const { directus } = useDirectus();
  const { logout } = useAuth();
  const lastActivityTimeRef = useRef(Date.now());

  useEffect(() => {
    const handleUserActivity = () => {
      lastActivityTimeRef.current = Date.now();
    };

    const checkSessionExpiration = async () => {
      const activityTimeout = 5 * 60 * 100000000; // 1 minute
      const alertTime = activityTimeout - 100000000; // Show alert 10 seconds before expiration

      const checkInterval = setInterval(() => {
        const currentTime = Date.now();
        const lastActivityTime = lastActivityTimeRef.current;

        if (currentTime - lastActivityTime > activityTimeout) {
          clearInterval(checkInterval);
          Swal.fire({
            title: "Tu sesión está a punto de expirar",
            text: "¿Quieres mantener la sesión abierta?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Sí, mantener sesión abierta",
            cancelButtonText: "No, cerrar sesión",
            timer: 10000,
            timerProgressBar: true,
          }).then((result) => {
            if (result.isConfirmed) {
              lastActivityTimeRef.current = Date.now();
              checkSessionExpiration();
            } else {
              logout();
            }
          });
        }
      }, 1000);

      return () => clearInterval(checkInterval);
    };

    document.addEventListener("mousedown", handleUserActivity);
    document.addEventListener("keydown", handleUserActivity);

    checkSessionExpiration();

    return () => {
      document.removeEventListener("mousedown", handleUserActivity);
      document.removeEventListener("keydown", handleUserActivity);
    };
  }, [directus, logout]);

  return null; // No renderiza ningún componente
};

export default SessionExpirationChecker;