import React from "react";

export const InputText = ({
    disabled,
    register,
    descripcion,
    rules,
    type,
    placeholder,
    name,
    errors,
    onChange,
    value,
    maxLength,
    onKeyPress,
    noRequerido,
    onPaste,
    onCopy,
}) => {
    const isTextArea = type === "textArea";

    return (
        <>
            {descripcion ? (
                <label htmlFor={name}>
                    <span className="dot">•</span> {descripcion}{" "}
                    {noRequerido ? (
                        <span className="requerido"> (opcional)</span>
                    ) : (
                        ""
                    )}
                </label>
            ) : (
                ""
            )}
            {isTextArea ? (
                <textarea
                    disabled={disabled}
                    maxLength={maxLength}
                    {...register(name, rules)}
                    placeholder={placeholder}
                    className={`form-control ${errors && "form-error"}`}
                    name={name}
                    onChange={onChange}
                    value={value}
                    onKeyPress={onKeyPress}
                    autoComplete="off"
                    onPaste={onPaste}
                    onCopy={onCopy}
                ></textarea>
            ) : (
                <input
                    disabled={disabled}
                    maxLength={maxLength}
                    {...register(name, rules)}
                    type={type}
                    placeholder={placeholder}
                    className={`form-control ${errors && "form-error"}`}
                    name={name}
                    onChange={onChange}
                    value={value}
                    onKeyPress={onKeyPress}
                    autoComplete="off"
                    onPaste={onPaste}
                    onCopy={onCopy}
                />
            )}
            {errors && (
                <small className="text-danger-seguro">{errors.message}</small>
            )}
        </>
    );
};
 