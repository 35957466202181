import { useState } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  FormGroup,
  Form,
} from "react-bootstrap";
import styles from "./CambioContrasena.module.css";
import { RutInput } from "./RutInput";
import { useDirectus } from "../../Providers/DirectusProvider";
import swal from "sweetalert";
import CorreoInput from "./CorreoInput";

export default function CambioContrasena({ setCambioContrasena }) {
  const [rut, setRut] = useState("");
  const [rutValido, setRutValido] = useState(false);
  const [correo, setCorreo] = useState("");
  const [correo2, setCorreo2] = useState("");
  const [correoValido, setCorreoValido] = useState(false);
  const { directus } = useDirectus();

  const onclick = async () => {
    swal.stopLoading();
    if (rut === "" || correo === "" || correo2 === "") {
      swal({
        title: "Error",
        text: "Todos los campos son obligatorios",
        icon: "error",
        button: "Aceptar",
        className: styles.swalButton,
      });
      return;
    }
    if (!rutValido) {
      swal({
        title: "Error",
        text: "El rut ingresado no es válido",
        icon: "error",
        button: "Aceptar",
        className: styles.swalButton,
      });
      return;
    }
    if (correo !== correo2) {
      swal({
        title: "Error",
        text: "Los correos ingresados no son iguales",
        icon: "error",
        button: "Aceptar",
        className: styles.swalButton,
      });
      return;
    }
    if (!correoValido) {
      swal({
        title: "Error",
        text: "El correo ingresado no es válido",
        icon: "error",
        button: "Aceptar",
        className: styles.swalButton,
      });
      return;
    }
    const { raw: respuesta } = await directus.transport.post(
      "/cambio-contrasena",
      {
        rut,
        correo: correo,
      }
    );
    if (respuesta.mensaje === "Se ha enviado el correo exitosamente.") {
      swal({
        title: "¡Exito!",
        text: "Por favor revise su correo.",
        icon: "success",
        button: "Aceptar",
        className: styles.swalButton,
      });
      setCambioContrasena(false);
    } else {
      swal({
        title: "Error",
        text: "Este rut no está asociado a este beneficio",
        icon: "error",
        button: "Aceptar",
        className: styles.swalButton,
      });
    }
  };

  const Volver = () => {
    setCambioContrasena(false);
  };

  return (
    <>
      <Container fluid="sm">
        <Row className="h-100">
          <Col></Col>
          <Col className="align-items-center" md="auto">
            <Card className={styles.test}>
              <Card.Body className={styles.container}>
                <div className={`text-center ${styles.div_principal}`}>
                  <Card.Text as={"p"} className={styles.color_text}>
                    <strong>¿Olvidaste tu contraseña?</strong>
                  </Card.Text>
                  <Form>
                    <FormGroup
                      className={`mb-1 ${styles.RutInput}`}
                      controlId="formBasicRut"
                    >
                      <RutInput
                        onChange={setRut}
                        placeholder="Ingrese su rut"
                        onIsValid={setRutValido}
                        value={rut}
                        autofocus
                      />
                    </FormGroup>
                    <FormGroup
                      className={`text-center ${styles.RutInput}`}
                      controlId="formBasicEmail"
                    >
                      <CorreoInput
                        onChange={setCorreo}
                        placeholder="Ingrese su correo"
                        onIsValid={setCorreoValido}
                      />
                    </FormGroup>
                    <FormGroup
                      className={`text-center ${styles.RutInput}`}
                      controlId="formBasicEmail"
                    >
                      <CorreoInput
                        onChange={setCorreo2}
                        placeholder="Reingrese su correo"
                        onIsValid={setCorreoValido}
                      />
                    </FormGroup>
                    <FormGroup
                      className={styles.div_button}
                      controlId="formBasicButton"
                    >
                      <Button
                        className={styles.boton}
                        variant="primary"
                        size="lg"
                        onClick={onclick}
                      >
                        Cambiar contraseña
                      </Button>
                    </FormGroup>
                    <FormGroup
                      className={styles.textVolver}
                      controlId="formBasicReturn"
                    >
                      <Card.Text className={styles.color_text} onClick={Volver}>
                        Volver
                      </Card.Text>
                    </FormGroup>
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col></Col>
        </Row>
      </Container>
    </>
  );
}
