import * as React from "react";
import { Link } from "react-router-dom";

import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import LogoutIcon from "@mui/icons-material/Logout";
import Collapse from "@mui/material/Collapse";
import AddCardIcon from "@mui/icons-material/AddCard";
import SpeedIcon from "@mui/icons-material/Speed";
import StorefrontIcon from "@mui/icons-material/Storefront";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import FeedIcon from "@mui/icons-material/Feed";
import InfoIcon from "@mui/icons-material/Info";
import LibraryAddCheckIcon from "@mui/icons-material/LibraryAddCheck";
import StoreIcon from "@mui/icons-material/Store";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";

import { useAuth } from "../Hooks/useAuth";

const drawerWidth = 240;

const isOpenedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "isOpen",
})(({ theme, isOpen }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(isOpen && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "isOpen",
})(({ theme, isOpen }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(isOpen && {
        ...isOpenedMixin(theme),
        "& .MuiDrawer-paper": isOpenedMixin(theme),
    }),
    ...(!isOpen && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

export const MiniDrawer = ({ outlet, tittle }) => {
    const userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const { logout } = useAuth();

    const theme = useTheme();
    const [openDrawer, setOpenDrawer] = React.useState(false);
    //const outlet = useOutlet(setIframeOn);

    const handleLogout = () => {
        logout();
    };

    const handleDrawerOpen = () => {
        setOpenDrawer(true);
    };

    const handleDrawerClose = () => {
        setOpenDrawer(false);
    };

    const [openList, setOpenList] = React.useState(false);

    const handleClick = () => {
        setOpenList(!openList);
    };

    return (
        <Box className="div-contenedor" sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" isOpen={openDrawer}>
                <Toolbar className="heaver-nav">
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(openDrawer && { display: "none" }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <div className="contenedorHeader">
                        <Typography variant="h6" noWrap component="div">
                            {tittle} -
                            {" " +
                                userLogin.first_name +
                                " " +
                                userLogin.last_name}
                        </Typography>

                        <div className="marcas">
                            <img
                                alt="logo_gc"
                                className="logo_gc"
                                src={
                                    "https://seguros.ticmedical.cl/archivos-publicos/archivos/Mhaite_Logo.png"
                                }
                            />
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" isOpen={openDrawer}>
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === "rtl" ? (
                            <ChevronRightIcon />
                        ) : (
                            <ChevronLeftIcon />
                        )}
                    </IconButton>
                </DrawerHeader>
                <Divider />

                <List
                    sx={{
                        width: "100%",
                        maxWidth: 360,
                        bgcolor: "background.paper",
                    }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                >
                    {userLogin.role.name != "admin_beneficiarios" && (
                    <>
                    <ListItemButton
                        component={Link}
                        to={"/parorama-actual"}
                        sx={{
                            minHeight: 48,
                            justifyContent: openDrawer ? "initial" : "center",
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: openDrawer ? 3 : "auto",
                                justifyContent: "center",
                            }}
                        >
                            <SpeedIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Panorama Actual"
                            sx={{ opacity: openDrawer ? 1 : 0 }}
                        />
                    </ListItemButton>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            justifyContent: openDrawer ? "initial" : "center",
                            px: 2.5,
                        }}
                        onClick={handleClick}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: openDrawer ? 3 : "auto",
                                justifyContent: "center",
                            }}
                        >
                            <StorefrontIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Ventas"
                            sx={{ opacity: openDrawer ? 1 : 0 }}
                        />
                        {openList && openDrawer ? (
                            <ExpandLess sx={{ opacity: openDrawer ? 1 : 0 }} />
                        ) : openDrawer ? (
                            <ExpandMore sx={{ opacity: openDrawer ? 1 : 0 }} />
                        ) : (
                            ""
                        )}
                    </ListItemButton>
                    </>)}
                    <Collapse in={openList} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {userLogin.role.name != "admin_beneficiarios" && (
                                <>
                                    <ListItemButton
                                        sx={{ pl: openDrawer ? 4 : 2.5 }}
                                        component={Link}
                                        to={"/asistente-ventas"}
                                    >
                                        <ListItemIcon>
                                            <AddCardIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Asistente ventas" />
                                    </ListItemButton>
                                    <ListItemButton
                                        sx={{ pl: openDrawer ? 4 : 2.5 }}
                                        component={Link}
                                        to={"/ventas-completas"}
                                    >
                                        <ListItemIcon>
                                            <LibraryAddCheckIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Ventas Completas" />
                                    </ListItemButton>
                                    <ListItemButton
                                        sx={{ pl: openDrawer ? 4 : 2.5 }}
                                        component={Link}
                                        to={"/quote"}
                                    >
                                        <ListItemIcon>
                                            <StoreIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Cotizaciones" />
                                    </ListItemButton>
                                    {userLogin.role.name ===
                                        "admin_vendedor" && (
                                        <ListItemButton
                                            sx={{ pl: openDrawer ? 4 : 2.5 }}
                                            component={Link}
                                            to={"/informes-venta"}
                                        >
                                            <ListItemIcon>
                                                <FeedIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Informes" />
                                        </ListItemButton>
                                    )}
                                </>
                            )}
                        </List>
                    </Collapse>
                    {userLogin.role.name != "admin_beneficiarios" && (
                        <>
                            <ListItemButton
                                component={Link}
                                to={"/mhaite-seguros"}
                                sx={{
                                    minHeight: 48,
                                    justifyContent: openDrawer
                                        ? "initial"
                                        : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: openDrawer ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary="Seguros Mhaite"
                                    sx={{ opacity: openDrawer ? 1 : 0 }}
                                />
                            </ListItemButton>
                        </>
                    )}
                    {userLogin.role.name === "admin_beneficiarios" && (
                        <ListItemButton
                            component={Link}
                            to={"/admin-beneficiarios"}
                            sx={{
                                minHeight: 48,
                                justifyContent: openDrawer
                                    ? "initial"
                                    : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: openDrawer ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <PersonAddAlt1Icon />
                            </ListItemIcon>
                            <ListItemText
                                primary="Beneficiarios"
                                sx={{ opacity: openDrawer ? 1 : 0 }}
                            />
                        </ListItemButton>
                    )}
                    <ListItemButton
                        onClick={(event) => {
                            handleLogout();
                        }}
                        sx={{
                            minHeight: 48,
                            justifyContent: openDrawer ? "initial" : "center",
                            px: 2.5,
                        }}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: 0,
                                mr: openDrawer ? 3 : "auto",
                                justifyContent: "center",
                            }}
                        >
                            <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary="Salir"
                            sx={{ opacity: openDrawer ? 1 : 0 }}
                        />
                    </ListItemButton>
                </List>
                <Divider />
            </Drawer>
            <Box
                className="change-iframe"
                component="main"
                sx={{ flexGrow: 1, p: 3 }}
            >
                <DrawerHeader />
                {outlet}
            </Box>
        </Box>
    );
};
