import React, { useState } from "react";
import { InputText } from "./InputText";
import { useDirectus } from "../../Providers/DirectusProvider";

export const InputRut = ({
    disabled,
    register,
    name,
    errors,
    onChange,
    maxLength,
    requerido,
    datos = null,
    descripcion,
    value = "",
    esCarga = false,
    cargas,
    checkingDisabled = false,
    ventaRemota = false,
    validarNuevaCarga = true,
    cargasEliminadas = false,
    desdeAgregarCargas = false,
    cargaIncompleta = false,
    cargaCompleta = false,
}) => {
    const [rutFormateado, setRutFormateado] = useState("-");
    const { directus } = useDirectus();

    const calculoDV = (T) => {
        let M = 0,
            S = 1;
        for (; T; T = Math.floor(T / 10))
            S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
        return S ? S - 1 : "k";
    };
    const revisaValido = (rut, dv) => {
        if (rut) {
            return calculoDV(rut).toString().toLowerCase() === dv.toLowerCase();
        } else {
            return false;
        }
    };

    const validarRut = (rutIngresado) => {
        if (
            esCarga &&
            (!rutIngresado || rutIngresado == "-" || rutIngresado == "") &&
            !disabled
        ) {
            return true;
        } else {
            if (value != "") {
                rutIngresado = value;
            }
            const limpio = rutIngresado.replace(/[^(0-9|k)]/gi, "");
            let dv = limpio[limpio.length - 1];
            let rut = limpio.slice(0, -1);
            return revisaValido(rut, dv);
        }

        //return true o false;
    };

    const limpiarRut = (e) => {
        setRutFormateado("");
        const limpio = e.target.value.replace(/[^(0-9|k)]/gi, "");
        let dv = limpio[limpio.length - 1];
        let rut = limpio.slice(0, -1);
        if (limpio.length >= 1) {
            setRutFormateado(`${rut}-${dv}`);
        }
        e.target.value = limpio;
        if (onChange) {
            onChange(e);
        }
    };

    const validateIgualdadTitular = (rut) => {
        if (!checkingDisabled && validarNuevaCarga) {
            if (esCarga) {
                return (
                    rut.replace(/[^(0-9|k)]/gi, "") !=
                    datos.rut.replace(/[^(0-9|k)]/gi, "")
                );
            } else {
                return true;
            }
        }
        return true;
    };
    const validateIgualdadCargas = (rut) => {
        if (!checkingDisabled && validarNuevaCarga) {
            var conteoRepetido = 0;
            cargas.forEach(async (carga) => {
                var rutIngresado = rut.replace(/[^(0-9|k)]/gi, "");
                if (carga.rut == rutIngresado) {
                    conteoRepetido = conteoRepetido + 1;
                }
            });
            if (conteoRepetido <= 1 && esCarga) {
                return true;
            } else if (conteoRepetido > 0) {
                return false;
            } else {
                return true;
            }
        }
        return true;
    };

    const checkRut = async (rutIngresado) => {
        //lo pasamos a endpoint por seguridad
        if (
            ((!checkingDisabled || !ventaRemota) && (validarNuevaCarga || !disabled)) ||
            desdeAgregarCargas
        ) {
            if (
                rutIngresado != "" &&
                rutIngresado &&
                !cargaCompleta &&
                !disabled
            ) {
                if (validarRut(rutIngresado)) {
                    var consulta = await directus.transport.get(
                        "/flow/check_rut",
                        {
                            params: {
                                rut: rutIngresado,
                                idBeneficiario:
                                (validarNuevaCarga || !disabled) || desdeAgregarCargas
                                        ? null
                                        : datos.idBeneficiario,
                            },
                        }
                    );
                    return consulta.raw;
                } else {
                    return true;
                }
            }
            return true;
        }
        return true;
    };

    return (
        <InputText
            disabled={disabled}
            register={register}
            descripcion={descripcion}
            rules={{
                /*required: "campo obligatorio",*/
                validate: {
                    validateRut: (v) => validarRut(v) || "RUT incorrecto",
                    validateIgualdadTitular: (v) =>
                        validateIgualdadTitular(v) ||
                        "No puede ser igual al del titular",
                    validateIgualdadCargas: (v) =>
                        validateIgualdadCargas(v) || "RUT no puede repetirse",
                    RutExist: async (v) =>
                        (await checkRut(v)) || "Ya se encuentra registrado",
                },
            }}
            type="text"
            placeholder="Ingrese Rut"
            name={name}
            onChange={limpiarRut}
            value={
                validarNuevaCarga || cargasEliminadas || cargaIncompleta
                    ? rutFormateado
                    : value || ventaRemota
                    ? value
                    : rutFormateado
            }
            errors={errors[name]}
            maxLength={maxLength}
            requerido={requerido}
            onPaste={(e) => {
                e.preventDefault();
                return false;
            }}
            onCopy={(e) => {
                e.preventDefault();
                return false;
            }}
        />
    );
};
