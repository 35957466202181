import { useOutletContext } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import { useDirectus } from "../../Providers/DirectusProvider";
import { SalesFormEdit } from "./SalesFormEdit";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

export const EditSale = (props) => {
    let { state } = useLocation();
    const dataPage = useOutletContext();
    const { formState: {}, } = useForm({ mode: "all" });
    const { directus } = useDirectus();
    const [mostrarFormulario, setMostrarFormulario] = useState("");
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [idBeneficiario, setIdBeneficiario] = useState("");
    const [newClientRut, setNewClientRut] = useState("");
    const [dataBene, setDataBene] = useState("");
    const [dataPlan, setDataPlan] = useState("");
    const [dataCargas, setDataCargas] = useState("");
    const [cantCargasInicial, setCantCargasInicial] = useState([]);

    /*LOADING*/
    const [openLoading, setOpenLoading] = React.useState(false);
    const handleCloseLoading = () => {
        setOpenLoading(false);
    };
    const handleToggleLoading = () => {
        setOpenLoading(!openLoading);
    };

    //CONSULTAR BENEFICIARIO
    useEffect(() => {
        //consultar datos
        //consultar datos de beneficiario
        async function getDataBeneficiario() {
            setIdBeneficiario(
                state.dataBene.idBeneficiarioPlan.idBeneficiario.idBeneficiario
            );
            setDataBene(state.dataBene.idBeneficiarioPlan.idBeneficiario);
            setNewClientRut(
                state.dataBene.idBeneficiarioPlan.idBeneficiario.rut
            );

            setDataCargas(state.dataBene.idBeneficiarioPlan.idBeneficiario.cargas);
            setDataPlan(state.dataBene.idBeneficiarioPlan.idPlan);            
            setBtnDisabled(true);
            const res = await directus.items("plan").readByQuery({
                filter: {
                    idPlan: state.dataBene.idBeneficiarioPlan.idPlan.idPlan,
                },
                fields: ["*.*"],
                limit: 1,
            });
            setCantCargasInicial(
                state.dataBene.idBeneficiarioPlan.idPlan.sku.split("_")[1]
            );
        }
        getDataBeneficiario();
        //obtener link de pago
    }, [idBeneficiario]);

    return (
        <div className="container-page">
            <br />
            <div className={mostrarFormulario}>
                <SalesFormEdit
                    newClientRut={newClientRut}
                    btnDisabled={btnDisabled}
                    idVendedor={dataPage.id}
                    handleCloseLoading={handleCloseLoading}
                    handleToggleLoading={handleToggleLoading}
                    idBeneficiarioEdit={idBeneficiario}
                    dataBene={dataBene}
                    dataPlan={dataPlan}
                    dataCargas={dataCargas}
                    ventaRemota={true}
                    setNewClientRut={setNewClientRut}
                    cantCargasInicial={state.dataBene.idBeneficiarioPlan.idPlan.sku.split("_")[1]}
                />
            </div>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};
