import React, { useState, useEffect } from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";
import { MiniDrawer } from "./MiniDrawer";
import { useDirectus } from "../Providers/DirectusProvider";
import { useLocalStorage } from "../Hooks/useLocalStorage";
import SessionExpirationChecker from "./SessionExpirationChecker";

export const ProtectedLayout = () => {
    const { autenticado, directus } = useDirectus();
    const { user, logout } = useAuth();
    const [isLoading, setLoading] = useState(true);
    const [userLogin, setUserLogin] = useLocalStorage("userLogin", "");
    const [empresasUsuario, setEmpresasUsuario] = useLocalStorage([]);
    const outlet = useOutlet(userLogin);
    const tittle =
        outlet.props.children.props.children.props.children.props.tittle;

    useEffect(() => {
        if (userLogin === "") {
            async function getDatosUser() {
                const res = await directus.users.me
                    .read({
                        fields: ["*.*"],
                    })
                    .then(async (response) => {
                        console.log(response.id);
                        const vendedor = await directus
                            .items("vendedor")
                            .readByQuery({
                                fields: ["*"],
                                filter: {
                                    idUsuario: response.id,
                                },
                            })
                            .then(async (responseSell) => {
                                console.log("responseSell");
                                console.log(responseSell);
                                
                                localStorage.setItem(
                                    "idVendedor",
                                    responseSell.data[0].idVendedor
                                );
                                setUserLogin(response);
                                const segurosUsuario = await directus
                                    .items("vendedor_admin_seguro")
                                    .readByQuery({
                                        fields: ["*"],
                                        filter: {
                                            idVendedor: responseSell.data[0].idVendedor,
                                        },
                                    })
                                    .then(async (responseSeguro) => {
                                        console.log(responseSeguro.data);
                                        localStorage.setItem(
                                            "setEmpresasUsuario",
                                            responseSeguro.data
                                        );
                                        setEmpresasUsuario(responseSeguro.data);
                                        setLoading(false);
                                    });
                            });
                    });
            }
            getDatosUser();
        } else {
            setLoading(false);
        }
    }, [directus]);

    if (!user) {
        return <Navigate to="/" />;
    } else {
        if (!autenticado) {
            logout();
        }
    }

    if (isLoading) {
        return <div className="App">Loading...</div>;
    }
    return (
        <div>
            <SessionExpirationChecker />
            <MiniDrawer
                outlet={outlet}
                tittle={tittle}
                userLogin={userLogin}
                empresasUsuario={empresasUsuario}
                logout={logout}
            />
        </div>
    );
};
