import React from "react";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";

const ProcesoSummary = ({ procesoSummary, onOpenModal }) => {
    const renderResumen = (resumen) => {
        return (
            <Paper elevation={3} style={{ padding: "15px", marginTop: "15px" }}>
                <Typography variant="h6" gutterBottom>
                    Resumen del Proceso
                </Typography>
                <ul>
                    {Object.entries(resumen)
                        .filter(([key]) => key !== "Listado errores" && key !== "idClienteEmpresa" && key !== "idPlan")
                        .map(([key, value]) => (
                            <li key={key}>
                                <strong>{key}:</strong> {value}
                            </li>
                        ))}
                </ul>
            </Paper>
        );
    };

    const formatKey = (key) => {
        const keyFormats = {
            rut: "RUT",
            dv: "DV",
            nombres: "Nombres",
            apellidoPaterno: "Apellido Paterno",
            apellidoMaterno: "Apellido Materno",
            fechaNacimiento: "Fecha de nacimiento",
            celular: "Celular",
            email: "Email",
            FEC_INI_VIG: "Inicio vigencia",
            telefono: "Teléfono",
            genero: "Género",
            titular: "Titular",
            idPlan: "idPlan",
            idClienteEmpresa: "idClienteEmpresa",
        };

        return keyFormats[key] || key;
    };

    const renderErrores = (errores) => {
        return (
            <div>
                <Paper
                    elevation={3}
                    style={{ padding: "15px", marginTop: "15px" }}
                >
                    <Typography variant="h6" gutterBottom>
                        Detalle de listado de errores
                    </Typography>
                    {errores.map((error, index) => (
                        <div key={index}>
                            <Paper
                                elevation={3}
                                style={{ padding: "15px", marginTop: "15px" }}
                            >
                                <Typography variant="subtitle1">
                                    {error.razon}
                                </Typography>
                                <Grid container spacing={2}>
                                    {Object.entries(error.data)
                                        .filter(([key]) => key !== "idClienteEmpresa" && key !== "idPlan")
                                        .map(([key, value]) => (
                                            <Grid item xs={6} sm={3} key={key}>
                                                <Typography variant="subtitle2">
                                                    <strong>
                                                        {formatKey(key)}:
                                                    </strong>{" "}
                                                    {value}
                                                </Typography>
                                            </Grid>
                                        ))}
                                </Grid>
                            </Paper>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={() => onOpenModal(error)} // Llama a la función para abrir el modal
                            >
                                Registrar beneficiario de forma manual
                            </Button>
                        </div>
                    ))}
                </Paper>
            </div>
        );
    };

    const errores = procesoSummary["Listado errores"] || [];

    return (
        <div>
            {renderResumen(procesoSummary)}
            {errores.length > 0 && renderErrores(errores)}
        </div>
    );
};

export default ProcesoSummary;
