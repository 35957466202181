import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import { useDirectus } from "../../Providers/DirectusProvider";
import { InputRut } from "./InputRut";
import { InputText } from "./InputText";
import { InputEmail } from "./InputEmail";
import { SalesFormExtendedEdit } from "./SalesFormExtendedEdit";
import { CargasEdit } from "./CargasEdit";

import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Moment from "moment";
import { InputSelect } from "./InputSelect";

import "./SalesForm.css";

export const SalesFormEdit = ({
    newClientRut,
    btnDisabled,
    idVendedor,
    handleCloseLoading,
    handleToggleLoading,
    idBeneficiarioEdit = null,
    dataBene = null,
    dataPlan = null,
    ventaRemota = false,
    setNewClientRut = false,
    cantCargasInicial = null,
    dataCargas = null,
}) => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        setFocus,
        clearErrors,
        unregister,
        setValue,
    } = useForm({ mode: "all" });
    const { directus } = useDirectus();
    const [planes, setPlanes] = useState([]);
    const [generos, setGeneros] = useState([]);
    const [regiones, setRegiones] = useState([]);
    const [comunas, setComunas] = useState([]);
    const [nombreRegion, setNombreRegion] = useState([]);
    const [parentescos, setParentescos] = useState([]);
    const [formExtended, SetFormExtended] = useState([]);
    const [idBeneficiario, setIdBeneficiario] = useState(null);
    /* variables cargas */
    const [cargas, setCargas] = useState([]);
    const [cantidadCargas, setCantidadCargas] = useState(0);
    const [quitarCarga, setQuitarCarga] = useState(false);
    const [cantCargas, setCantCargas] = useState(0);
    const [textButton, setTextButton] = useState("Enviar Formulario");
    const [cargasEliminadas, SetCargasEliminadas] = useState(false);
    const [dataVentaRemotaCarga, setDataVentaRemotaCarga] = useState([]);
    const [customerIdRemoto, setCustomerIdRemoto] = useState(false);
    const [planInicial, setPlanInicial] = useState(false);
    const [edicionDatosRemota, setEdicionDatosRemota] = useState(true);
    const [asignarcionCompletada, setAsignarcionCompletada] = useState(false);
    const [datosBasicosCarga, setdatosBasicosCarga] = useState(false);

    const [datos, setDatos] = useState({
        rut: "",
        nombres: "",
        primer_apellido: "",
        segundo_apellido: "",
        genero: "",
        fechaNacimiento: "",
        celular: "",
        telefono: "",
        email: "",
        email_confirmacion: "",
        direccion: "",
        nro_direccion: "",
        referencia: "",
        region: "",
        comuna: "",
        idBeneficiario: "",
        plan: "",
    });

    const reglaEdadMin = (date) => {
        var edadMinima = Moment().subtract(18, "years").format("YYYY-MM-DD");
        return date < edadMinima;
    };
    const reglaEdadMax = (date) => {
        var edadMaxima = Moment().subtract(65, "years").format("YYYY-MM-DD");
        return date > edadMaxima;
    };

    const checkComuna = (c) => {
        return datos.comuna != "";
    };

    const continuarFormulario = () => {
        SetFormExtended([]);
        SetFormExtended([{ id: 1 }]);

        //por ahora que no haga más
    };

    //AGREGAR AL FORMULARIO LAS CARGAS
    const agregarCarga = useCallback(
        (cantidadCarga = null, sumandoUnaCarga = false) => {
            if (cantidadCarga > 0) {
                let cantCargas = null;
                setQuitarCarga(false);
                setCantidadCargas(0);
                cantCargas = cantidadCarga;

                if (cantCargas && cantCargas > 0) {
                    setCantidadCargas(cantCargas);
                    if (sumandoUnaCarga) {
                        setCargas([
                            ...cargas,
                            {
                                id: 3,
                                idBeneCarga: "",
                                fechaNacimiento: "",
                                email: "",
                                email_confirmacion: "",
                            },
                        ]);
                    } else {
                        if (cantCargas == 2) {
                            setCargas([
                                {
                                    id: 1,
                                    idBeneCarga: "",
                                    fechaNacimiento: "",
                                    email: "",
                                    email_confirmacion: "",
                                },
                                {
                                    id: 2,
                                    idBeneCarga: "",
                                    fechaNacimiento: "",
                                    email: "",
                                    email_confirmacion: "",
                                },
                            ]);
                        } else {
                            setCargas([
                                {
                                    id: 1,
                                    idBeneCarga: "",
                                    fechaNacimiento: "",
                                    email: "",
                                    email_confirmacion: "",
                                },
                                {
                                    id: 2,
                                    idBeneCarga: "",
                                    fechaNacimiento: "",
                                    email: "",
                                    email_confirmacion: "",
                                },
                                {
                                    id: 3,
                                    idBeneCarga: "",
                                    fechaNacimiento: "",
                                    email: "",
                                    email_confirmacion: "",
                                },
                            ]);
                        }
                    }
                }
            } else {
                quitarCargas();
            }
        },
        [setQuitarCarga, setCargas, setCantidadCargas, cargas]
    );
    //QUITAR CARGAS
    async function quitarCargas() {
        setQuitarCarga(false);
        setCargas([]);
        setCantidadCargas(0);
        setCantCargas(0);
    }
    const eliminarCargas = useCallback(
        async (cantCargasAlEliminar, planSeleccionado) => {
            //asignar plan con cargas
            const planSuscripcion = await directus.items("plan").readByQuery({
                fields: ["*"],
                filter: {
                    idPlan: planSeleccionado,
                },
            });
            var sku = planSuscripcion.data[0].sku;
            let cantCargas = sku.split("_")[1];
            let goForm = cantCargas > 0;
            let textAlert =
                cantCargas > 0
                    ? "con un número menor de cargas al actual, sus cargas deben ser ingresadas nuevamente"
                    : "sin cargas sus cargas deben ser eliminadas";
            let confirmButtonText =
                cantCargas > 0
                    ? "Actualizar cargas"
                    : "Quitar Cargas y cambiar plan";
            //validar cargas
            Swal.fire({
                title:
                    "<h5 style='text-align: justify;'>" +
                    "Debido a que seleccionó un plan " +
                    textAlert +
                    " para poder continuar con el proceso de pago. </h5><h5 style='text-align: center;'>¿Desea cambiar de plan?" +
                    "</h5>",
                showCancelButton: true,
                confirmButtonText: confirmButtonText,
                cancelButtonText: "Cancelar",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    //quitar cargas
                    removeCargas(dataBene.idBeneficiario);
                    //ir a formulario
                    if (goForm) {
                        cargas.forEach((carga) => {
                            //limpiar todo campo
                            carga.nombres = "";
                            carga.primer_apellido = "";
                            carga.segundo_apellido = "";
                            carga.email = "";
                            carga.email_confirmacion = "";
                            carga.genero = "";
                            carga.contacto = "";
                            carga.telefono = "";
                            carga.parentesco = "";
                            carga.fechaNacimiento = "";
                            carga.rut = "";
                            carga.idBeneCarga = "";

                            setValue("nombres_" + carga.id, "");
                            setValue("primer_apellido_" + carga.id, "");
                            setValue("segundo_apellido_" + carga.id, "");
                            setValue("email_" + carga.id, "");
                            setValue("email_confirmacion_" + carga.id, "");
                            setValue("genero_" + carga.id, "");
                            setValue("contacto_" + carga.id, "");
                            setValue("telefono_" + carga.id, "");
                            setValue("parentesco_" + carga.id, "");
                            setValue("fechaNacimiento_" + carga.id, "");
                            setValue("rut_" + carga.id, "");
                        });
                        setDataVentaRemotaCarga([]);
                        SetCargasEliminadas(true);
                        const planSuscripcion = await directus
                            .items("plan")
                            .readByQuery({
                                fields: ["*"],
                                filter: {
                                    idPlan: planSeleccionado,
                                },
                            });
                        var sku = planSuscripcion.data[0].sku;
                        //registrar cliente en flow
                        var dataCustomerPlan = {
                            skuPlan: sku,
                            idBeneficiario: dataBene.idBeneficiario,
                            actualizarCargas: true,
                        };
                        let customerEditPlan = await actualizarPlan(
                            dataCustomerPlan
                        );
                        agregarCarga(cantCargasAlEliminar);
                    } else {
                        const planSuscripcion = await directus
                            .items("plan")
                            .readByQuery({
                                fields: ["*"],
                                filter: {
                                    idPlan: planSeleccionado,
                                },
                            });
                        var sku = planSuscripcion.data[0].sku;
                        //registrar cliente en flow
                        var dataCustomerPlan = {
                            skuPlan: sku,
                            idBeneficiario: dataBene.idBeneficiario,
                        };
                        let customerEditPlan = await actualizarPlan(
                            dataCustomerPlan
                        );
                        let idCliente = customerIdRemoto;
                        //registrar metodo de pago en flow
                        handleToggleLoading();
                        if (customerEditPlan) {
                            let customerRegister = null;
                            var dataCustomerRegister = {
                                customerId: idCliente,
                            };
                            try {
                                customerRegister = await registerCustomer(
                                    dataCustomerRegister
                                );
                                handleCloseLoading();
                                Swal.fire({
                                    customClass: {
                                        title: "titulo-venta",
                                    },
                                    title: "Datos del beneficiario guardados correctamente ",
                                    icon: "success",
                                    confirmButtonText: "Aceptar",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        navigate("/asistente-ventas", {
                                            success: true,
                                        });
                                    } else {
                                        navigate("/asistente-ventas", {
                                            success: true,
                                        });
                                    }
                                });
                            } catch (error) {
                                Swal.fire({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Ocurrio un error intentando registrar cliente",
                                });
                            }
                        }
                    }
                } else {
                    //asignar plan anterior a plan
                    datos.plan = planInicial;
                    setValue("plan", planInicial);
                }
            });
        },
        [directus, setIdBeneficiario, customerIdRemoto, dataBene, planInicial]
    );
    //ELIMINAR CARGAS
    const removeCargas = async (idBeneficiario) => {
        var dataDeletePlan = { idBeneficiario: idBeneficiario };
        const removeCargas = await getFlow(dataDeletePlan, "delete/cargas");
        return removeCargas;
    };

    const onSubmitDatosBeneficario = (data) => enviarFormulario();

    const HandleInputChange = (event) => {
        if (datos.rut == "") {
            datos["rut"] = newClientRut;
        }
        if (document.activeElement.name === event.target.name) {
            if (event.target.name == "plan") {
                //validar cambio de plan
                checkPlan(event.target.value);
            }
            if (event.target.name == "region") {
                var index = event.nativeEvent.target.selectedIndex;
                setNombreRegion(event.target[index].text);
                setDatos({
                    ...datos, //una copia para no ir borrando
                    [event.target.name]: event.target.value,
                });
                if (datos.comuna === "" && !ventaRemota) {
                    setDatos({
                        ...datos, //una copia para no ir borrando
                        ["comuna"]: "",
                    });
                }
            } else {
                setDatos({
                    ...datos, //una copia para no ir borrando
                    [event.target.name]: event.target.value,
                });
            }
        } else {
            datos[event.target.name] = "";
        }
    };

    const forzarError = (input, error) => {
        //datos[input] = datos[input];
        setFocus(input);
        setError(input, { type: "custom", message: error });
    };

    const clearRut = (valueRut) => {
        if (valueRut) {
            const limpio = valueRut.replace(/[^(0-9|k)]/gi, "");
            let M = 0,
                S = 1;
            for (; valueRut; valueRut = Math.floor(valueRut / 10))
                S = (S + (valueRut % 10) * (9 - (M++ % 6))) % 11;
            let dv = S ? S - 1 : "k";
            return `${limpio}-${dv}`;
        } else {
            return "";
        }
    };

    //REGISTRAR PAGO DE CLIENTE
    const actualizarPlan = async (dataCustomerRegister) => {
        const customerCreate = await getFlow(
            dataCustomerRegister,
            "update/plan"
        );
        return customerCreate;
    };

    //LLAMADA FLOW
    const getFlow = useCallback(
        async (parametros, accion, method = "post") => {
            try {
                let resApi = null;
                if (method === "get") {
                    resApi = await directus.transport.get("/flow/" + accion);
                } else {
                    resApi = await directus.transport.post("/flow/" + accion, {
                        parametros,
                    });
                }

                return resApi.raw;
            } catch (error) {
                //levantar error y mostrarlo en template
                return error.response.raw;
            }
        },
        [directus]
    );

    //CREAR CLIENTE
    const createCustomer = async (dataCustomerCreate) => {
        const customerCreate = await getFlow(
            dataCustomerCreate,
            "customer/create"
        );
        return customerCreate;
    };

    const registerCustomer = async (dataCustomerRegister) => {
        const customerRegister = await getFlow(
            dataCustomerRegister,
            "customer/register/remoto"
        );
        return customerRegister;
    };
    const checkPlan = useCallback(
        async (planSeleccionado) => {
            const planSuscripcion = await directus.items("plan").readByQuery({
                fields: ["*"],
                filter: {
                    idPlan: planSeleccionado,
                },
            });
            var sku = planSuscripcion.data[0].sku;
            let cantCargas = sku.split("_")[1];
            //validar cargas
            const newCantidad = cantCargas;
            const oldCantidad = cantCargasInicial;
            if (oldCantidad != newCantidad) {
                if (
                    (oldCantidad == 0 || newCantidad > oldCantidad) &&
                    newCantidad > 0
                ) {
                    agregarCarga(
                        cantCargas,
                        oldCantidad > 0 && cantCargas != cargas.length
                    );
                    //si plan actual es sin cargas y plan nuevo va con cargas : agregarCargas()
                } else if (newCantidad == 0 || newCantidad < oldCantidad) {
                    eliminarCargas(cantCargas, planSeleccionado);
                    //si plan actual es con cargas y plan nuevo es sin cargas o menos cargas : eliminarCargas()
                }
            }
        },
        [directus, createCustomer, registerCustomer, setIdBeneficiario]
    );

    const enviarFormulario = useCallback(
        async (planSeleccionado) => {
            handleToggleLoading();
            let beneficiario = false;
            try {
                beneficiario = await directus.transport.post(
                    "/flow/beneficiario/remoto",
                    { datos, cargas, formExtended }
                );
            } catch (error) {
                let inputError = error.errors[0].extensions.field;
                handleCloseLoading();
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text:
                        "El " +
                        inputError +
                        " ingresado ya se encuentra registrado.",
                }).then((result) => {
                    if (!result.isDenied) {
                        forzarError(
                            inputError,
                            inputError + " ya se encuentra registrado"
                        );
                    }
                });

                beneficiario = false;
            }
            if (beneficiario.raw.status == 401) {
                handleCloseLoading();
                var inputError = beneficiario.raw.extensions.field;
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text:
                        "El " +
                        inputError +
                        " ingresado ya se encuentra registrado.",
                }).then((result) => {
                    if (!result.isDenied) {
                        forzarError(
                            inputError,
                            inputError + " ya se encuentra registrado"
                        );
                    }
                });

                beneficiario = false;
            } else {
                let customerCreate = false;
                let idCliente = false;

                if (datos.idBeneficiario != "" && ventaRemota) {
                    idCliente = dataBene.customerId;
                } else {
                    datos.idBeneficiario = beneficiario.raw;
                    setIdBeneficiario(beneficiario.raw);
                    const planSuscripcion = await directus
                        .items("plan")
                        .readByQuery({
                            fields: ["*"],
                            filter: {
                                idPlan: datos.plan,
                            },
                        });
                    var idPlan = planSuscripcion.data[0].sku;
                    const vendedor = await directus
                        .items("vendedor")
                        .readByQuery({
                            fields: ["*"],
                            filter: {
                                idUsuario: idVendedor,
                            },
                        });
                    var dataCustomerCreate = {
                        name: datos.nombres,
                        email: datos.email,
                        externalId: beneficiario.raw,
                        skuPlan: idPlan,
                        vendedor: vendedor.data[0].idVendedor,
                        ventaRemota: true,
                        ventaCompleta: formExtended.length > 0,
                    };
                    customerCreate = await createCustomer(dataCustomerCreate);
                    idCliente = customerCreate.customerId;
                }
                if (planInicial != datos.plan) {
                    const planSuscripcion = await directus
                        .items("plan")
                        .readByQuery({
                            fields: ["*"],
                            filter: {
                                idPlan: datos.plan,
                            },
                        });
                    var sku = planSuscripcion.data[0].sku;
                    //registrar cliente en flow
                    var dataCustomerPlan = {
                        skuPlan: sku,
                        idBeneficiario: idBeneficiarioEdit,
                        mandarCorreo: true,
                    };
                    let customerEditPlan = await actualizarPlan(
                        dataCustomerPlan
                    );
                }
                if (idCliente && !ventaRemota) {
                    handleToggleLoading();
                    //MANDAR A TODO OK
                    if (formExtended.length > 0) {
                        var dataCustomerRegister = {
                            customerId: idCliente,
                        };
                        try {
                            const customerRegister = await registerCustomer(
                                dataCustomerRegister
                            );

                            handleCloseLoading();
                            /*Swal.fire({
                                customClass: {
                                    title: "titulo-venta",
                                },
                                title: "¡Venta registrada correctamente! Ahora el beneficiario debe completar su suscripción ingresando a su correo.",
                                icon: "success",
                                confirmButtonText: "Aceptar",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    navigate("/asistente-ventas", {
                                        success: true,
                                    });
                                } else {
                                    navigate("/asistente-ventas", {
                                        success: true,
                                    });
                                }
                            });*/
                        } catch (error) {
                            handleCloseLoading();
                            Swal.fire({
                                icon: "error",
                                title: "Oops...",
                                text: "Ocurrio un error intentando registrar cliente",
                            });
                        }
                        //consultar coustumer id
                        //registrar suscripción
                        //obtener token y enviar al correo
                    } else {
                        handleCloseLoading();
                        Swal.fire({
                            customClass: {
                                title: "titulo-venta",
                            },
                            title: "¡Venta registrada correctamente! Ahora el beneficiario debe completar su suscripción ingresando a su correo.",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate("/asistente-ventas", {
                                    success: true,
                                });
                            } else {
                                navigate("/asistente-ventas", {
                                    success: true,
                                });
                            }
                        });
                    }
                } else if (ventaRemota) {
                    handleCloseLoading();
                    Swal.fire({
                        customClass: {
                            title: "titulo-venta",
                        },
                        title: "Datos del beneficiario guardados correctamente ",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/asistente-ventas", {
                                success: true,
                            });
                        } else {
                            navigate("/asistente-ventas", {
                                success: true,
                            });
                        }
                    });
                } else {
                    handleCloseLoading();
                    //error por correo
                    if (
                        customerCreate.message ==
                        "Internal Server Error - email is not valid"
                    ) {
                        var errorFlow =
                            "Ocurrio un error intentando registrar su cuenta en la plataforma de pagos";
                        datos.email = "";
                        datos.email_confirmacion = "";
                        setError("email_confirmacion", {
                            type: "custom",
                            message: "El email no existe",
                        });
                        setError("email", {
                            type: "custom",
                            message: "El email no existe",
                        });
                    } else {
                        var errorFlow =
                            "Ocurrio un error intentando registrar su cuenta en la plataforma de pagos, vuelta a inventar en otro momento";
                    }
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: errorFlow,
                    });
                }
            }
        },
        [datos, directus, forzarError, setError]
    );

    //CONSULTAR BENEFICIARIO
    useEffect(() => {
        agregarCarga(cantCargasInicial);
        async function getPlanes() {
            const res = await directus.items("plan").readByQuery({
                fields: ["*"],
                filter: {
                    idSeguro: 1,
                    activo: true,
                },
            });
            setPlanes(res.data);
        }
        getPlanes();
        async function getGeneros() {
            const res = await directus
                .items("genero")
                .readByQuery({ fields: ["*"] });
            setGeneros(res.data);
        }
        async function getParentescos() {
            const res = await directus
                .items("parentesco")
                .readByQuery({ fields: ["*"] });
            setParentescos(res.data);
        }
        getGeneros();
        getParentescos();
        async function getRegiones() {
            const res = await directus.items("region").readByQuery({
                fields: ["*"],
                filter: {
                    idPais: 1,
                },
                sort: ["orden"],
            });
            setRegiones(res.data);
        }
        getRegiones();
    }, [directus]);

    useEffect(() => {
        async function getComunas() {
            if (datos.region != "") {
                const res = await directus.items("comuna").readByQuery({
                    fields: ["*"],
                    filter: {
                        idRegion: datos.region,
                        nombre: {
                            _neq: nombreRegion,
                        },
                    },
                    sort: ["nombre"],
                });
                setComunas(res.data);
            }
        }
        getComunas();
    }, [directus, datos, nombreRegion]);

    useEffect(() => {
        async function asignarValoresEdit() {
            console.log('ENTRO A ASIGNAR VALORES');
            if (idBeneficiarioEdit && !asignarcionCompletada) {
                setAsignarcionCompletada(true);
                setCantCargas(cantCargasInicial);
                setIdBeneficiario(idBeneficiarioEdit);
                setTextButton("Actualizar Datos");
                continuarFormulario();
                //asignar plan inicial
                setPlanInicial(dataPlan.idPlan);
                //asignar valores
                if (ventaRemota || idBeneficiarioEdit) {
                    //cambiar info plan
                    datos.plan = dataPlan.idPlan;
                    setValue("plan", dataPlan.idPlan);
                    datos.nombres = dataBene.nombres;
                    datos.primer_apellido = dataBene.primerApellido;
                    datos.segundo_apellido = dataBene.segundoApellido;
                    datos.email = dataBene.email;
                    datos.email_confirmacion = dataBene.email;
                    datos.rut = clearRut(dataBene.rut);
                    setNewClientRut(clearRut(dataBene.rut));
                    datos.idBeneficiario = dataBene.idBeneficiario;
                    datos.celular = dataBene.celular;
                    datos.telefono = dataBene.telefono;

                    setValue("nombres", dataBene.nombres);
                    setValue("primer_apellido", dataBene.primerApellido);
                    setValue("segundo_apellido", dataBene.segundoApellido);
                    setValue("email", dataBene.email);
                    setValue("email_confirmacion", dataBene.email);
                    setValue("rut", clearRut(dataBene.rut));
                    setValue("celular", dataBene.celular);
                    setValue("telefono", dataBene.telefono);
                    //agregar datos adicionales y cargas
                    if (dataBene.idGenero) {
                        datos.genero = dataBene.idGenero.idGenero;

                        datos.direccion = dataBene.direccion.split(",")[0];
                        datos.nro_direccion = dataBene.direccion.split(",")[1];
                        datos.referencia = dataBene.direccion.split(",")[2];
                        datos.fechaNacimiento = Moment(
                            dataBene.fechaNacimiento
                        ).format("YYYY-MM-DD");

                        setValue("genero", dataBene.idGenero.idGenero);

                        setValue("direccion", dataBene.direccion.split(",")[0]);
                        setValue(
                            "nro_direccion",
                            dataBene.direccion.split(",")[1]
                        );
                        setValue(
                            "referencia",
                            dataBene.direccion.split(",")[2]
                        );
                        //setFechaMui(Moment(dataBene.fechaNacimiento));
                        setValue(
                            "fechaNacimiento",
                            Moment(dataBene.fechaNacimiento).format(
                                "YYYY-MM-DD"
                            )
                        );
                        const resComuna = await directus
                            .items("comuna")
                            .readByQuery({
                                fields: ["*"],
                                filter: {
                                    codComuna: dataBene.comuna.codComuna,
                                },
                                limit: 1,
                            });
                        const resRegion = await directus
                            .items("region")
                            .readByQuery({
                                fields: ["*"],
                                filter: {
                                    idRegion: resComuna.data[0].idRegion,
                                },
                                limit: 1,
                            });
                        setNombreRegion(resRegion.data[0].nombre);
                        const resComunas = await directus
                            .items("comuna")
                            .readByQuery({
                                fields: ["*"],
                                filter: {
                                    idRegion: resComuna.data[0].idRegion,
                                    nombre: {
                                        _neq: resRegion.data[0].nombre,
                                    },
                                },
                                sort: ["nombre"],
                            });
                        datos.region = resComuna.data[0].idRegion;
                        setValue("region", resComuna.data[0].idRegion);
                        setComunas(resComunas.data);
                        setTimeout(() => {
                            datos.comuna = dataBene.comuna.codComuna;
                            setValue("comuna", dataBene.comuna.codComuna);
                        }, 250);
                        if (!cargasEliminadas && planInicial != datos.plan) {
                            console.log('ENTRO A ASIGNAR VALORES CARGAS');
                            cargas.forEach((carga) => {
                                const datosCarga =
                                    dataCargas[carga.id - 1].idCarga;
                                if (datosCarga) {
                                    carga.nombres = datosCarga.nombres;
                                    carga.primer_apellido =
                                        datosCarga.primerApellido;
                                    carga.segundo_apellido =
                                        datosCarga.segundoApellido;
                                    carga.email = datosCarga.email;
                                    carga.email_confirmacion = datosCarga.email;
                                    carga.genero = datosCarga.idGenero;
                                    carga.contacto = datosCarga.celular;
                                    carga.telefono = datosCarga.telefono;

                                    carga.parentesco = dataCargas[carga.id - 1]
                                        .idParentesco
                                        ? dataCargas[carga.id - 1].idParentesco
                                              .idParentesco
                                        : null;

                                    carga.fechaNacimiento = Moment(
                                        datosCarga.fechaNacimiento
                                    ).format("YYYY-MM-DD");
                                    carga.rut = datosCarga.rut
                                        ? clearRut(datosCarga.rut)
                                        : null;
                                    carga.idBeneCarga =
                                        datosCarga.idBeneficiario;

                                    carga.esCargaCompleta =
                                        dataCargas[carga.id - 1].cargaCompleta;
                                    setValue(
                                        "nombres_" + carga.id,
                                        datosCarga.nombres
                                    );
                                    setValue(
                                        "primer_apellido_" + carga.id,
                                        datosCarga.primerApellido
                                    );
                                    setValue(
                                        "segundo_apellido_" + carga.id,
                                        datosCarga.segundoApellido
                                    );
                                    setValue(
                                        "email_" + carga.id,
                                        datosCarga.email
                                    );
                                    setValue(
                                        "email_confirmacion_" + carga.id,
                                        datosCarga.email
                                    );
                                    setValue(
                                        "genero_" + carga.id,
                                        datosCarga.idGenero
                                    );
                                    setValue(
                                        "contacto_" + carga.id,
                                        datosCarga.celular
                                    );
                                    setValue(
                                        "telefono_" + carga.id,
                                        datosCarga.telefono
                                    );
                                    setValue(
                                        "parentesco_" + carga.id,
                                        dataCargas[carga.id - 1].idParentesco
                                            ? dataCargas[carga.id - 1]
                                                  .idParentesco.idParentesco
                                            : null
                                    );
                                    setValue(
                                        "fechaNacimiento_" + carga.id,
                                        Moment(
                                            datosCarga.fechaNacimiento
                                        ).format("YYYY-MM-DD")
                                    );

                                    setValue(
                                        "rut_" + carga.id,
                                        datosCarga.rut
                                            ? clearRut(datosCarga.rut)
                                            : null
                                    );
                                } else {
                                    if (!cargasEliminadas) {
                                        //limpiar todo campo
                                        carga.nombres = "";
                                        carga.primer_apellido = "";
                                        carga.segundo_apellido = "";
                                        carga.email = "";
                                        carga.email_confirmacion = "";
                                        carga.genero = "";
                                        carga.celular = "";
                                        carga.telefono = "";
                                        carga.parentesco = "";
                                        carga.fechaNacimiento = "";
                                        carga.rut = "";
                                        carga.idBeneCarga = "";
                                        carga.esCargaCompleta = null;
                                        setValue("nombres_" + carga.id, "");
                                        setValue(
                                            "primer_apellido_" + carga.id,
                                            ""
                                        );
                                        setValue(
                                            "segundo_apellido_" + carga.id,
                                            ""
                                        );
                                        setValue("email_" + carga.id, "");
                                        setValue(
                                            "email_confirmacion_" + carga.id,
                                            ""
                                        );
                                        setValue("genero_" + carga.id, "");
                                        setValue("celular_" + carga.id, "");
                                        setValue("telefono_" + carga.id, "");
                                        setValue("parentesco_" + carga.id, "");
                                        setValue(
                                            "fechaNacimiento_" + carga.id,
                                            ""
                                        );
                                        setValue("rut_" + carga.id, "");
                                    }
                                }
                            });
                        }
                    }
                } else if (cargasEliminadas) {
                    //recorriendo cargas eliminadas
                    cargas.forEach((carga) => {
                        //limpiar todo campo
                        carga.nombres = "";
                        carga.primer_apellido = "";
                        carga.segundo_apellido = "";
                        carga.email = "";
                        carga.email_confirmacion = "";
                        carga.genero = "";
                        carga.celular = "";
                        carga.telefono = "";
                        carga.parentesco = "";
                        carga.fechaNacimiento = "";
                        carga.rut = "";
                        carga.idBeneCarga = "";
                        carga.esCargaCompleta = null;

                        setValue("nombres_" + carga.id, "");
                        setValue("primer_apellido_" + carga.id, "");
                        setValue("segundo_apellido_" + carga.id, "");
                        setValue("email_" + carga.id, "");
                        setValue("email_confirmacion_" + carga.id, "");
                        setValue("genero_" + carga.id, "");
                        setValue("celular_" + carga.id, "");
                        setValue("telefono_" + carga.id, "");
                        setValue("parentesco_" + carga.id, "");
                        setValue("fechaNacimiento_" + carga.id, "");
                        setValue("rut_" + carga.id, "");
                    });
                }
            }
        }
        asignarValoresEdit();
    }, [dataBene, cargas, asignarcionCompletada, setAsignarcionCompletada]);

    let listPlanes = planes.map((p) => (
        <option key={p.idPlan} value={p.idPlan} sku={p.sku}>
            {p.nombre}
        </option>
    ));
    let listGenero = generos.map((g) => (
        <option key={g.idGenero} value={g.idGenero}>
            {g.nombre}
        </option>
    ));
    let listRegion = regiones.map((r) => (
        <option key={r.idRegion} value={r.idRegion}>
            {r.nombre}
        </option>
    ));
    let listComuna = comunas.map((r) => (
        <option key={r.codComuna} value={r.codComuna}>
            {r.nombre}
        </option>
    ));
    let listParentesco = parentescos.map((p) => (
        <option key={p.idParentesco} value={p.idParentesco}>
            {p.descripcion}
        </option>
    ));

    //
    let listCargas = cargas.map((c) => (
        <CargasEdit
            key={c.id}
            id={c.id}
            register={register}
            errors={errors}
            listGenero={listGenero}
            listParentesco={listParentesco}
            reglaEdadMin={reglaEdadMin}
            reglaEdadMax={reglaEdadMax}
            cargas={cargas}
            setCargas={setCargas}
            datosBeneficiarioTitular={datos}
            clearErrors={clearErrors}
            datos={datos}
            ventaRemota={ventaRemota}
            btnDisabled={btnDisabled}
            dataVentaRemota={
                dataBene != []
                    ? dataCargas[c.id - 1]
                        ? dataCargas[c.id - 1].idCarga
                        : undefined
                    : null
            }
            edicionDatosRemota={edicionDatosRemota}
            cargasEliminadas={cargasEliminadas}
            clearRut={clearRut}
            infoCarga={c}
        />
    ));

    let addSaleFormExtended = formExtended.map((c) => (
        <SalesFormExtendedEdit
            key={c.id}
            id={c.id}
            register={register}
            errors={errors}
            listGenero={listGenero}
            listParentesco={listParentesco}
            reglaEdadMin={reglaEdadMin}
            reglaEdadMax={reglaEdadMax}
            cargas={cargas}
            setCargas={setCargas}
            datosBeneficiarioTitular={datos}
            clearErrors={clearErrors}
            datos={datos}
            HandleInputChange={HandleInputChange}
            listRegion={listRegion}
            checkComuna={checkComuna}
            listComuna={listComuna}
            listCargas={listCargas}
            datosBasicosCarga={datosBasicosCarga}
            setdatosBasicosCarga={setdatosBasicosCarga}
        />
    ));
    return (
        <div className="">
            <Card sx={{ minWidth: 275, padding: 1 }}>
                <ListItemText primary="Datos de beneficiario" />
                <Divider light />
                <form
                    onSubmit={handleSubmit(onSubmitDatosBeneficario)}
                    autoComplete="false"
                    className="formularioSale"
                >
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputRut
                                descripcion="Rut"
                                disabled={btnDisabled}
                                register={register}
                                name="rut"
                                errors={errors}
                                onChange={HandleInputChange}
                                maxLength={10}
                                datos={datos}
                                value={!ventaRemota ? newClientRut : datos.rut}
                                cargas={cargas}
                                ventaRemota={ventaRemota}
                                validarNuevaCarga={false}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputSelect
                                register={register}
                                descripcion="Seleccione plan a ofrecer"
                                rules={{
                                    required: "campo obligatorio",
                                }}
                                id="plan"
                                name="plan"
                                option="Seleccione un plan"
                                options={listPlanes}
                                errors={errors.plan}
                                value={datos.plan}
                                onChange={HandleInputChange}
                                infoPlan={datos.plan}
                            />
                        </Box>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Nombres"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: (v) =>
                                        !!v.trim() || "campo obligatorio",
                                }}
                                type="text"
                                placeholder="Ingrese Nombre"
                                name="nombres"
                                errors={errors.nombres}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                maxLength={150}
                                value={datos.nombres}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Primer Apellido"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: (v) =>
                                        !!v.trim() || "campo obligatorio",
                                }}
                                type="text"
                                placeholder="Ingrese primer apellido"
                                name="primer_apellido"
                                errors={errors.primer_apellido}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={datos.primer_apellido}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Segundo Apellido"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: (v) =>
                                        !!v.trim() || "campo obligatorio",
                                }}
                                type="text"
                                placeholder="Ingrese segundo apellido"
                                name="segundo_apellido"
                                errors={errors.segundo_apellido}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={datos.segundo_apellido}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputEmail
                                disabled={btnDisabled}
                                register={register}
                                name="email"
                                errors={errors}
                                onChange={HandleInputChange}
                                datos={datos}
                                cargas={cargas}
                                clearErrors={clearErrors}
                                descripcion="Email"
                                value={datos.email}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputEmail
                                disabled={btnDisabled}
                                register={register}
                                name="email_confirmacion"
                                errors={errors}
                                onChange={HandleInputChange}
                                datos={datos}
                                cargas={cargas}
                                confirmar={true}
                                clearErrors={clearErrors}
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                descripcion="Confirmar Email"
                                value={datos.email_confirmacion}
                            />
                        </Box>
                    </div>
                    <div className="col-md-12 p-3">{addSaleFormExtended}</div>

                    <div className="col-md-12 btn-acciones">
                        {!formExtended.length > 0 ? (
                            <Box
                                sx={{
                                    "& > :not(style)": { m: 1 },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={continuarFormulario}
                                >
                                    Mostrar formulario completo
                                </Button>
                            </Box>
                        ) : (
                            ""
                        )}
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="success"
                            >
                                {textButton}
                            </Button>
                        </Box>
                    </div>
                </form>
            </Card>
        </div>
    );
};
