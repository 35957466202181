import { Fragment, useCallback, useEffect, useState } from "react";
import { useDirectus } from "../../Providers/DirectusProvider";
import { Form } from "react-bootstrap";
import "./Login.css";
import Swal from "sweetalert2";
import { RutInput } from "./RutInput";
import CambioContrasena from "./CambioContrasena.js";
import { useAuth } from "../../Hooks/useAuth";

export const Login = () => {
    const [rut, setRut] = useState("");
    const [contrasena, setContrasena] = useState("");
    const [rutValido, setRutValido] = useState(false);
    const { directus, IniciarSesion } = useDirectus();
    const [cambioContrasena, setCambioContrasena] = useState(false);
    const { login } = useAuth();

    const tryLogin = useCallback(async () => {
        if (!rutValido) {
            Swal.fire({
                title: "Rut inválido",
                text: "El rut que ha ingresado no es válido",
                icon: "error",
            });

            return;
        }
        await IniciarSesion(rut, contrasena);
        const { token } = directus._auth;

        if (token) {
            login({
                token: token,
            });
        }
        //aquí hicimos login tanto en directus como wordpress, deberíamos redirigir a la página de wordpress que corresponda
    }, [directus, rut, contrasena, IniciarSesion, rutValido]);

    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear();
    }, [directus]);
    if (cambioContrasena) {
        return <CambioContrasena setCambioContrasena={setCambioContrasena} />;
    }

    return (
        <Fragment>
            <div className="Login">
                <div className="Auth-form-container">
                    <div className="Auth-form">
                        <div className="Auth-form-content">
                            <div>
                                <img
                                    alt="logo_mhaite"
                                    className="logo_mhaite_pago"
                                    src={
                                        "https://seguros.ticmedical.cl/archivos-publicos/archivos/mhaite_logo.png"
                                    }
                                />
                            </div>
                            <h3 className="Auth-form-title">
                                Portal Administrador de ventas
                            </h3>
                            <div className="form-group mt-3">
                                <label>RUT</label>
                                <RutInput
                                    onChange={setRut}
                                    placeholder="RUT"
                                    onIsValid={setRutValido}
                                    autofocus
                                />
                            </div>
                            <div className="form-group mt-3">
                                <label>contraseña</label>
                                <Form.Control
                                    size="sm"
                                    type="password"
                                    className="form-control mt-1"
                                    placeholder="contraseña"
                                    onChange={(event) =>
                                        setContrasena(event.target.value)
                                    }
                                />
                            </div>
                            <div className="d-grid gap-2 mt-3">
                                <button
                                    className="btn btn-primary btn-login"
                                    onClick={tryLogin}
                                >
                                    Ingresar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Login;
