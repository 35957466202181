import xlsx from "xlsx";

export const FileValidation = (file, shouldValidate = false) => {
  // Obtener el archivo de la instancia del evento si es necesario
  const realFile = file instanceof FileList ? file[0] : file;

  const isValidFileType = (fileName) => {
      console.log(fileName, /\.(xlsx)$/i.test(fileName));
      return /\.(xlsx)$/i.test(fileName);
  };

  const isValidFileContent = (fileContent) => {
      return true; // Modifica según tus necesidades
  };

  const isValidFileSize = (fileSize) => {
      return fileSize <= 10485760; // 10 MB en bytes
  };

  const validateFile = () => {
        console.log('asdasd');
      if (!realFile && shouldValidate) {
          return "El archivo es obligatorio.....";
      }

      if (realFile && !isValidFileType(realFile.name)) {
          console.log('ENTRO POR ERROR DE TIPO ');
          return "Tipo de archivo no válido. Se permiten archivos xlsx.";
      }

      if (realFile && !isValidFileContent(realFile)) {
          return "Contenido de archivo no válido.";
      }

      if (realFile && !isValidFileSize(realFile.size)) {
          return "El tamaño del archivo excede el límite permitido (10 MB).";
      }

      return null; // El archivo es válido
  };

  return validateFile();
};

export default FileValidation;