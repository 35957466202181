import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import { useDirectus } from "../../Providers/DirectusProvider";
import { InputRut } from "./InputRut";
import { InputText } from "./InputText";
import { InputEmail } from "./InputEmail";
import { SalesFormExtended } from "./SalesFormExtended";
import { Cargas } from "./Cargas";

import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Moment from "moment";
import { InputSelect } from "./InputSelect";

import "./SalesForm.css";

export const SalesForm = ({
    newClientRut,
    btnDisabled,
    idVendedor,
    handleCloseLoading,
    handleToggleLoading,
}) => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        setFocus,
        clearErrors,
    } = useForm({ mode: "all" });
    const { directus } = useDirectus();
    const [planes, setPlanes] = useState([]);
    const [generos, setGeneros] = useState([]);
    const [regiones, setRegiones] = useState([]);
    const [comunas, setComunas] = useState([]);
    const [nombreRegion, setNombreRegion] = useState([]);
    const [parentescos, setParentescos] = useState([]);
    const [formExtended, SetFormExtended] = useState([]);
    const [idBeneficiario, setIdBeneficiario] = useState(null);
    /* variables cargas */
    const [cargas, setCargas] = useState([]);
    const [cantidadCargas, setCantidadCargas] = useState(0);
    const [quitarCarga, setQuitarCarga] = useState(false);
    const [cantCargas, setCantCargas] = useState(0);

    const [datosBasicosCarga, setdatosBasicosCarga] = useState(false);

    const [datos, setDatos] = useState({
        rut: "",
        nombres: "",
        primer_apellido: "",
        segundo_apellido: "",
        genero: "",
        fechaNacimiento: "",
        contacto: "",
        telefono: "",
        email: "",
        email_confirmacion: "",
        direccion: "",
        nro_direccion: "",
        referencia: "",
        region: "",
        comuna: "",
        idBeneficiario: "",
        plan: "",
    });

    const reglaEdadMin = (date) => {
        var edadMinima = Moment().subtract(18, "years").format("YYYY-MM-DD");
        return date < edadMinima;
    };
    const reglaEdadMax = (date) => {
        var edadMaxima = Moment().subtract(65, "years").format("YYYY-MM-DD");
        return date > edadMaxima;
    };

    const checkComuna = (c) => {
        return datos.comuna != "";
    };

    const continuarFormulario = () => {
        SetFormExtended([]);
        SetFormExtended([{ id: 1 }]);

        //por ahora que no haga más
    };

    //AGREGAR AL FORMULARIO LAS CARGAS
    const agregarCarga = useCallback(
        (cantidadCarga = null) => {
            if (cantidadCarga > 0) {
                let cantCargas = null;
                setQuitarCarga(false);
                setCantidadCargas(0);
                cantCargas = cantidadCarga;

                if (cantCargas && cantCargas > 0) {
                    setCantidadCargas(cantCargas);

                    if (cantCargas == 2) {
                        setCargas([{ id: 1 }, { id: 2 }]);
                    } else {
                        setCargas([{ id: 1 }, { id: 2 }, { id: 3 }]);
                    }
                }
            } else {
                quitarCargas();
            }
        },
        [setQuitarCarga, setCargas, setCantidadCargas]
    );
    //QUITAR CARGAS
    async function quitarCargas() {
        setQuitarCarga(false);
        setCargas([]);
        setCantidadCargas(0);
        setCantCargas(0);
    }

    const onSubmitDatosBeneficario = (data) => enviarFormulario();

    const HandleInputChange = (event) => {
        if (datos.rut == "") {
            datos["rut"] = newClientRut;
        }
        if (document.activeElement.name === event.target.name) {
            if (event.target.name == "plan") {
                agregarCarga(
                    event.target.selectedOptions[0]
                        .getAttribute("sku")
                        .split("_")[1]
                );
            }
            if (event.target.name == "region") {
                var index = event.nativeEvent.target.selectedIndex;
                setNombreRegion(event.target[index].text);
                setDatos({
                    ...datos, //una copia para no ir borrando
                    [event.target.name]: event.target.value,
                    ["comuna"]: "",
                });
            } else {
                setDatos({
                    ...datos, //una copia para no ir borrando
                    [event.target.name]: event.target.value,
                });
            }
        } else {
            datos[event.target.name] = "";
        }
    };

    const forzarError = (input, error) => {
        //datos[input] = datos[input];
        setFocus(input);
        setError(input, { type: "custom", message: error });
    };

    //LLAMADA FLOW
    const getFlow = useCallback(
        async (parametros, accion, method = "post") => {
            try {
                let resApi = null;
                if (method === "get") {
                    resApi = await directus.transport.get("/flow/" + accion);
                } else {
                    resApi = await directus.transport.post("/flow/" + accion, {
                        parametros,
                    });
                }

                return resApi.raw;
            } catch (error) {
                //levantar error y mostrarlo en template
                return error.response.raw;
            }
        },
        [directus]
    );

    //CREAR CLIENTE
    const createCustomer = async (dataCustomerCreate) => {
        const customerCreate = await getFlow(
            dataCustomerCreate,
            "customer/create"
        );
        return customerCreate;
    };

    const registerCustomer = async (dataCustomerRegister) => {
        const customerRegister = await getFlow(
            dataCustomerRegister,
            "customer/register/remoto"
        );
        return customerRegister;
    };
    const enviarFormulario = useCallback(async () => {
        handleToggleLoading();
        let beneficiario = false;
        try {
            beneficiario = await directus.transport.post(
                "/flow/beneficiario/remoto",
                { datos, cargas, formExtended }
            );
        } catch (error) {
            let inputError = error.errors[0].extensions.field;
            handleCloseLoading();
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text:
                    "El " +
                    inputError +
                    " ingresado ya se encuentra registrado.",
            }).then((result) => {
                if (!result.isDenied) {
                    forzarError(
                        inputError,
                        inputError + " ya se encuentra registrado"
                    );
                }
            });

            beneficiario = false;
        }
        if (beneficiario.raw.status == 401) {
            handleCloseLoading();
            var inputError = beneficiario.raw.extensions.field;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text:
                    "El " +
                    inputError +
                    " ingresado ya se encuentra registrado.",
            }).then((result) => {
                if (!result.isDenied) {
                    forzarError(
                        inputError,
                        inputError + " ya se encuentra registrado"
                    );
                }
            });

            beneficiario = false;
        } else {
            datos.idBeneficiario = beneficiario.raw;
            setIdBeneficiario(beneficiario.raw);
            const planSuscripcion = await directus.items("plan").readByQuery({
                fields: ["*"],
                filter: {
                    idPlan: datos.plan,
                },
            });
            var idPlan = planSuscripcion.data[0].sku;
            const vendedor = await directus.items("vendedor").readByQuery({
                fields: ["*"],
                filter: {
                    idUsuario: idVendedor,
                },
            });
            var dataCustomerCreate = {
                name: datos.nombres,
                email: datos.email,
                externalId: beneficiario.raw,
                skuPlan: idPlan,
                vendedor: vendedor.data[0].idVendedor,
                ventaRemota: true,
                ventaCompleta: formExtended.length > 0,
            };
            const customerCreate = await createCustomer(dataCustomerCreate);

            if (customerCreate.customerId) {
                handleToggleLoading();
                //MANDAR A TODO OK
                if (formExtended.length > 0) {
                    var dataCustomerRegister = {
                        customerId: customerCreate.customerId,
                    };
                    try {
                        const customerRegister = await registerCustomer(
                            dataCustomerRegister
                        );
                        handleCloseLoading();
                        Swal.fire({
                            customClass: {
                                title: "titulo-venta",
                            },
                            title: "¡Venta registrada correctamente! Ahora el beneficiario debe completar su suscripción ingresando a su correo.",
                            icon: "success",
                            confirmButtonText: "Aceptar",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                navigate("/asistente-ventas", {
                                    success: true,
                                });
                            } else {
                                navigate("/asistente-ventas", {
                                    success: true,
                                });
                            }
                        });
                    } catch (error) {
                        handleCloseLoading();
                        Swal.fire({
                            icon: "error",
                            title: "Oops...",
                            text: "Ocurrio un error intentando registrar cliente",
                        });
                    }
                    //consultar coustumer id
                    //registrar suscripción
                    //obtener token y enviar al correo
                } else {
                    handleCloseLoading();
                    Swal.fire({
                        customClass: {
                            title: "titulo-venta",
                        },
                        title: "¡Venta registrada correctamente! Ahora el beneficiario debe completar su suscripción ingresando a su correo.",
                        icon: "success",
                        confirmButtonText: "Aceptar",
                    }).then((result) => {
                        if (result.isConfirmed) {
                            navigate("/asistente-ventas", { success: true });
                        } else {
                            navigate("/asistente-ventas", { success: true });
                        }
                    });
                }
            } else {
                handleCloseLoading();
                //error por correo
                if (
                    customerCreate.message ==
                    "Internal Server Error - email is not valid"
                ) {
                    var errorFlow =
                        "Ocurrio un error intentando registrar su cuenta en la plataforma de pagos";
                    datos.email = "";
                    datos.email_confirmacion = "";
                    setError("email_confirmacion", {
                        type: "custom",
                        message: "El email no existe",
                    });
                    setError("email", {
                        type: "custom",
                        message: "El email no existe",
                    });
                } else {
                    var errorFlow =
                        "Ocurrio un error intentando registrar su cuenta en la plataforma de pagos, vuelta a inventar en otro momento";
                }
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: errorFlow,
                });
            }
        }
    }, [datos, directus, forzarError, setError]);

    //CONSULTAR BENEFICIARIO
    useEffect(() => {
        async function getPlanes() {
            const res = await directus
                .items("plan")
                .readByQuery({
                    fields: ["*"],
                    filter: {
                        idSeguro: 1,
                        activo: true
                    },
                });
            setPlanes(res.data);
        }
        getPlanes();
        async function getGeneros() {
            const res = await directus
                .items("genero")
                .readByQuery({ fields: ["*"] });
            setGeneros(res.data);
        }
        async function getParentescos() {
            const res = await directus
                .items("parentesco")
                .readByQuery({ fields: ["*"] });
            setParentescos(res.data);
        }
        getGeneros();
        getParentescos();
        async function getRegiones() {
            const res = await directus.items("region").readByQuery({
                fields: ["*"],
                filter: {
                    idPais: 1,
                },
                sort: ["orden"],
            });
            setRegiones(res.data);
        }
        getRegiones();
    }, [directus]);

    useEffect(() => {
        async function getComunas() {
            if (datos.region != "") {
                const res = await directus.items("comuna").readByQuery({
                    fields: ["*"],
                    filter: {
                        idRegion: datos.region,
                        nombre: {
                            _neq: nombreRegion,
                        },
                    },
                    sort: ["nombre"],
                });
                setComunas(res.data);
            }
        }
        getComunas();
    }, [directus, datos, nombreRegion]);

    let listPlanes = planes.map((p) => (
        <option key={p.idPlan} value={p.idPlan} sku={p.sku}>
            {p.nombre}
        </option>
    ));
    let listGenero = generos.map((g) => (
        <option key={g.idGenero} value={g.idGenero}>
            {g.nombre}
        </option>
    ));
    let listRegion = regiones.map((r) => (
        <option key={r.idRegion} value={r.idRegion}>
            {r.nombre}
        </option>
    ));
    let listComuna = comunas.map((r) => (
        <option key={r.codComuna} value={r.codComuna}>
            {r.nombre}
        </option>
    ));
    let listParentesco = parentescos.map((p) => (
        <option key={p.idParentesco} value={p.idParentesco}>
            {p.descripcion}
        </option>
    ));

    //
    let listCargas = cargas.map((c) => (
        <Cargas
            key={c.id}
            id={c.id}
            register={register}
            errors={errors}
            listGenero={listGenero}
            listParentesco={listParentesco}
            reglaEdadMin={reglaEdadMin}
            reglaEdadMax={reglaEdadMax}
            cargas={cargas}
            setCargas={setCargas}
            datosBeneficiarioTitular={datos}
            clearErrors={clearErrors}
            datos={datos}
        />
    ));

    let addSaleFormExtended = formExtended.map((c) => (
        <SalesFormExtended
            key={c.id}
            id={c.id}
            register={register}
            errors={errors}
            listGenero={listGenero}
            listParentesco={listParentesco}
            reglaEdadMin={reglaEdadMin}
            reglaEdadMax={reglaEdadMax}
            cargas={cargas}
            setCargas={setCargas}
            datosBeneficiarioTitular={datos}
            clearErrors={clearErrors}
            datos={datos}
            HandleInputChange={HandleInputChange}
            listRegion={listRegion}
            checkComuna={checkComuna}
            listComuna={listComuna}
            listCargas={listCargas}
            datosBasicosCarga={datosBasicosCarga}
            setdatosBasicosCarga={setdatosBasicosCarga}
        />
    ));
    return (
        <div className="">
            <Card sx={{ minWidth: 275, padding: 1 }}>
                <ListItemText primary="Datos de beneficiario" />
                <Divider light />
                <form
                    onSubmit={handleSubmit(onSubmitDatosBeneficario)}
                    autoComplete="false"
                    className="formularioSale"
                >
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputRut
                                descripcion="Rut"
                                disabled={btnDisabled}
                                register={register}
                                name="rut"
                                errors={errors}
                                onChange={HandleInputChange}
                                maxLength={10}
                                datos={datos}
                                value={newClientRut}
                                cargas={cargas}
                                validarNuevaCarga={false}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputSelect
                                register={register}
                                descripcion="Seleccione plan a ofrecer"
                                rules={{
                                    required: "campo obligatorio",
                                }}
                                id="plan"
                                name="plan"
                                option="Seleccione un plan"
                                options={listPlanes}
                                errors={errors.plan}
                                value={datos.plan}
                                onChange={HandleInputChange}
                                infoPlan={datos.plan}
                            />
                        </Box>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Nombres"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: (v) =>
                                        !!v.trim() || "campo obligatorio",
                                }}
                                type="text"
                                placeholder="Ingrese Nombre"
                                name="nombres"
                                errors={errors.nombres}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                maxLength={150}
                                value={datos.nombres}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Primer Apellido"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: (v) =>
                                        !!v.trim() || "campo obligatorio",
                                }}
                                type="text"
                                placeholder="Ingrese primer apellido"
                                name="primer_apellido"
                                errors={errors.primer_apellido}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={datos.primer_apellido}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Segundo Apellido"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: (v) =>
                                        !!v.trim() || "campo obligatorio",
                                }}
                                type="text"
                                placeholder="Ingrese segundo apellido"
                                name="segundo_apellido"
                                errors={errors.segundo_apellido}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={datos.segundo_apellido}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputEmail
                                register={register}
                                name="email"
                                errors={errors}
                                onChange={HandleInputChange}
                                datos={datos}
                                cargas={cargas}
                                clearErrors={clearErrors}
                                descripcion="Email"
                                value={datos.email}
                                titular={true}
                                required={true}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputEmail
                                register={register}
                                name="email_confirmacion"
                                errors={errors}
                                onChange={HandleInputChange}
                                datos={datos}
                                cargas={cargas}
                                confirmar={true}
                                clearErrors={clearErrors}
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                descripcion="Confirmar Email"
                                value={datos.email_confirmacion}
                                required={true}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Celular"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: (v) =>
                                        v.length > 8 ||
                                        "número de contacto incorrecto",
                                }}
                                type="text"
                                placeholder="Ingrese número de contacto"
                                name="contacto"
                                errors={errors.contacto}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={datos.contacto}
                                maxLength={11}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Teléfono"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: (v) =>
                                        v.length > 8 ||
                                        "número de teléfono incorrecto",
                                }}
                                type="text"
                                placeholder="Ingrese teléfono"
                                name="telefono"
                                errors={errors.telefono}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={datos.telefono}
                                maxLength={11}
                            />
                        </Box>
                    </div>
                    <div className="col-md-12 p-3">{addSaleFormExtended}</div>

                    <div className="col-md-12 btn-acciones">
                        {!formExtended.length > 0 ? (
                            <Box
                                sx={{
                                    "& > :not(style)": { m: 1 },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={continuarFormulario}
                                >
                                    Mostrar formulario completo
                                </Button>
                            </Box>
                        ) : (
                            ""
                        )}
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="success"
                            >
                                Enviar Formulario
                            </Button>
                        </Box>
                    </div>
                </form>
            </Card>
        </div>
    );
};
