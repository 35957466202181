import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PersonIcon from "@mui/icons-material/Person";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { saveAs } from "file-saver";
import { write, utils } from "xlsx";

import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Swal from "sweetalert2";
import Moment from "moment";
import "./AsistenteVenta.css";
import MUIDataTable from "mui-datatables";

import { Opciones } from "./Opciones";

import { useDirectus } from "../../Providers/DirectusProvider";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#4d94ff",
        color: theme.palette.common.white,
        fontSize: 16,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const rutCompleto = (rut) => {
    let rutLimpio = rut?.replace(/[^(0-9|k)]/gi, "");
    let M = 0,
        S = 1;
    let T = rutLimpio;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    var dv = S ? S - 1 : "k";
    return rutLimpio + "-" + dv;
};

export const Quote = (props) => {
    /*LOADING*/
    const [openLoading, setOpenLoading] = React.useState(false);
    const handleCloseLoading = () => {
        setOpenLoading(false);
    };
    const handleToggleLoading = () => {
        setOpenLoading(!openLoading);
    };

    const userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const idVendedor = JSON.parse(localStorage.getItem("idVendedor"));
    const { state } = useLocation();
    const { success } = state ? state : false; // Read values passed on state
    const [isLoading, setLoading] = useState(true);
    var begin = Moment().subtract(30, "days").format("YYYY-MM-DD");

    const { directus } = useDirectus();
    const [allQuote, setAllQuote] = useState([{}]);

    const columns = [
        {
            name: "idVendedor.nombre",
            label: "Vendedor",
        },
        {
            name: "idPlan.nombre",
            label: "Plan ofertado",
        },
        {
            name: "idRazon.descripcion",
            label: "Razón",
        },
        {
            name: "date_created",
            label: "Fecha envio cotización",
            options: {
                customBodyRender: (value) => Moment(value).format("YYYY-MM-DD"),
            },
        },
    ];
    const options = {
        textLabels: {
            body: {
                noMatch: "No se encontró información",
                toolTip: "Ordenar",
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "fila por página:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar Excel",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todo",
                title: "FILTROS",
                reset: "por defecto",
            },
            viewColumns: {
                title: "Mostrar columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada",
            },
        },
        filterType: "checkbox",
        /*responsive: "scroll",*/
        enableNestedDataAccess: ".",
        sortOrder: {
            name: "date_created",
            direction: "desc",
        },
        customToolbarSelect: () => {},
        onDownload: (buildHead, buildBody, columns, values) => {
            const fileType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";
            const json = values.reduce((result, val) => {
                const temp = {};
                val.data.forEach((v, idx) => {
                    if (columns[idx].label !== "Opciones") {
                        if (columns[idx].label === "Fecha envio cotización") {
                            temp[columns[idx].label] =
                                Moment(v).format("YYYY-MM-DD");
                        } else {
                            temp[columns[idx].label] = v;
                        }
                    }
                });
                result.push(temp);
                return result;
            }, []);

            const fileName =
                `Listado_cotizaciones_` + Moment().format("YYYY-MM-DD");
            const ws = utils.json_to_sheet(json);
            const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
            const excelBuffer = write(wb, { bookType: "xlsx", type: "array" });
            const data = new Blob([excelBuffer], { type: fileType });
            saveAs(data, fileName + fileExtension);
            // cancel default  CSV download from table
            return false;
        },
        selectableRows: "none",
        expandableRows: true,
        renderExpandableRow: (rowData, rowMeta) => {
            const dataExtra = allQuote[rowMeta.dataIndex];
            const infoBeneficiarioParse = JSON.parse(
                dataExtra.infoBeneficiario
            );
            const nombres = infoBeneficiarioParse.nombres;
            const telefono = infoBeneficiarioParse.telefono
                ? infoBeneficiarioParse.telefono
                : "Sin info";
            const comentario = infoBeneficiarioParse.comentario
                ? infoBeneficiarioParse.comentario
                : "Sin info";
            const email = infoBeneficiarioParse.email;

            return (
                <TableRow sx={{ margin: 0, paddingBottom: 0, paddingTop: 0 }}>
                    <TableCell
                        style={{ paddingBottom: 0, paddingTop: 0 }}
                        colSpan={7}
                    >
                        <Box
                            sx={{ margin: 0, paddingBottom: 0, paddingTop: 0 }}
                        >
                            <Table aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">
                                            Nombres Beneficiario
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            Teléfono
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            Email
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            Comentario
                                        </StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <StyledTableRow key={rowMeta.rowIndex}>
                                        <StyledTableCell align="left">
                                            {nombres}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {telefono}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {email}
                                        </StyledTableCell>
                                        <StyledTableCell align="left">
                                            {comentario}
                                        </StyledTableCell>
                                    </StyledTableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </TableCell>
                </TableRow>
            );
        },
    };
    //CONSULTAR VENTAS
    if (success) {
        Swal.fire({
            title: "Beneficiario registrado correctamente",
            text: "Ahora el beneficiario solo debe abrir el correo y seguir las instrucciones para completar el formulario",
            icon: "success",
        });
    }

    useEffect(() => {
        async function getAllQuote() {
            if (userLogin.role.name === "admin_vendedor") {
                await directus
                    .items("cotizacion")
                    .readByQuery({
                        fields: [
                            "idVendedor.nombre",
                            "idVendedor.idVendedor",
                            "idPlan.nombre",
                            "infoBeneficiario",
                            "date_created",
                            "idRazon.descripcion",
                        ],
                        limit: -1,
                        sort: ["date_created"],
                    })
                    .then(async (response) => {
                        setAllQuote(response.data);
                        setLoading(false);
                    });
            } else if (userLogin.role.name === "vendedor") {
                await directus
                    .items("cotizacion")
                    .readByQuery({
                        fields: [
                            "idVendedor.nombre",
                            "idVendedor.idVendedor",
                            "idPlan.nombre",
                            "infoBeneficiario",
                            "date_created",
                            "idRazon.descripcion",
                        ],
                        filter: {
                            idVendedor: idVendedor,
                        },
                        limit: -1,
                        sort: ["date_created"],
                    })
                    .then(async (response) => {
                        console.log("response.data");
                        console.log(response.data);
                        setAllQuote(response.data);
                        setLoading(false);
                    });
            }
        }
        getAllQuote();
    }, [directus, success, begin, idVendedor, userLogin.role.name]);

    if (isLoading) {
        return <div className="App">Loading...</div>;
    }
    return (
        <div className="container-page">
            <br />
            <Card
                className="p-0 contenedorTabla"
                sx={{ minWidth: 275, padding: 1 }}
            >
                <MUIDataTable
                    title={"Cotizaciones enviadas"}
                    data={allQuote}
                    columns={columns}
                    options={options}
                />
            </Card>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};
