import {} from "react-router-dom";
import React from "react";
import "./MhaiteSeguros.css";

export const MhaiteSeguros = (props) => {
    const ref = React.useRef();

    return (
        <div className="iframe-div">
            <iframe
                title="iframe-mhaite"
                ref={ref}
                id="iframe-mhaite"
                src="https://mhaite.com/mhaite-seguros/"
                width="100%"
                height="100%"
                style={{
                    width: "100%",
                    overflow: "auto",
                }}
            ></iframe>
        </div>
    );
};
