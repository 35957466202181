import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Moment from "moment";
import MUIDataTable from "mui-datatables";
import { useDirectus } from "../../Providers/DirectusProvider";
import Swal from "sweetalert2";
import PersonOffIcon from "@mui/icons-material/PersonOff";

export const ListBeneficiario = ({ idSeguro = null }) => {
    /*LOADING*/
    const [openLoading, setOpenLoading] = React.useState(false);
    const handleCloseLoading = () => {
        setOpenLoading(false);
    };
    const handleToggleLoading = () => {
        setOpenLoading(!openLoading);
    };
    const rutCompleto = (rut) => {
        let rutLimpio = rut?.replace(/[^(0-9|k)]/gi, "");
        let M = 0,
            S = 1;
        let T = rutLimpio;
        for (; T; T = Math.floor(T / 10))
            S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
        var dv = S ? S - 1 : "k";
        return rutLimpio + "-" + dv;
    };
    const [isLoading, setLoading] = useState(true);

    const { directus } = useDirectus();
    const [allBeneficiarios, setAllBeneficiarios] = useState([{}]);

    const columns = [
        {
            name: "idBeneficiario",
            label: "Identificador",
            options: {
                filter: false, // Desactivar la opción de "Filtrar tabla"
            },
        },
        {
            name: "nombreCompleto",
            label: "Nombre Completo",
            options: {
                filter: false, // Desactivar la opción de "Filtrar tabla"
            },
        },
        {
            name: "identificador",
            label: "Identificador",
            options: {
                customBodyRender: (value, meta) => {
                    const internacional =
                    allBeneficiarios[meta.currentTableData[meta.rowIndex].index].internacional;
                    if (!internacional) {
                        return value ? "RUT: "+rutCompleto(value) : "";
                    } else {
                        return "DNI: "+value || "";
                    }
                },
                filterType: "dropdown",
                filter: false,
            },
        },
        {
            name: "fechaEnrolamiento",
            label: "Fecha de Enrolamiento",
            options: {
                customBodyRender: (value) => {
                    return Moment(value).format("YYYY-MM-DD");
                },
                filter: false,
            },
        },
        {
            name: "nombre",
            label: "Nombre del Plan",
            options: {
                filter: false, // Desactivar la opción de "Filtrar tabla"
            },
        },
        {
            name: "estado",
            label: "Estado de Beneficio",
            options: {
                customBodyRender: (value) => {
                    return value ? "Activo" : "Inactivo";
                },
            },
        },
        {
            name: "estado",
            label: "Opciones",
            download: false,
            options: {
                customBodyRender: (value, tableMeta) => {
                    const rowIndex = tableMeta.rowIndex;
                    const record = allBeneficiarios[rowIndex];
                    const { idBeneficiarioPlan, estado } = record;

                    const handleDarDeBaja = async () => {
                        const confirmResult = await Swal.fire({
                            title: "¿Estás seguro que deseas dar de baja este beneficiario?",
                            icon: "question",
                            showCancelButton: true,
                            confirmButtonText: "Sí, dar de baja",
                            cancelButtonText: "Cancelar",
                        });

                        if (confirmResult.isConfirmed) {
                            console.log("ENTRO AL CONFIRM");
                            try {
                                console.log(
                                    "ENTRO AL CONFIRM 2",
                                    idBeneficiarioPlan
                                );
                                // Llamar al endpoint de baja_beneficiario con el idBeneficiarioPlan
                                const baja = await directus.transport.get(
                                    "/crearBeneficiario/darBaja",
                                    {
                                        params: { idBeneficiarioPlan },
                                    }
                                );
                                console.log(baja);
                                Swal.fire(
                                    "¡Baja exitosa!",
                                    "El beneficiario ha sido dado de baja.",
                                    "success"
                                );
                                setTimeout(() => {
                                    window.location.reload();
                                }, 3000);
                            } catch (error) {
                                console.error(
                                    "Error al dar de baja el beneficiario",
                                    error
                                );
                                Swal.fire(
                                    "Error",
                                    "Ocurrió un error al dar de baja el beneficiario.",
                                    "error"
                                );
                            }
                        }
                    };

                    return (
                        <div>
                            {value === 1 && (
                                <PersonOffIcon
                                    style={{ cursor: "pointer" }}
                                    onClick={handleDarDeBaja}
                                />
                            )}
                            {/* Puedes agregar más lógica según el valor aquí */}
                        </div>
                    );
                },
            },
        },
    ];

    const options = {
        textLabels: {
            body: {
                noMatch: "No se encontró información",
                toolTip: "Ordenar",
            },
            pagination: {
                next: "Siguiente",
                previous: "Anterior",
                rowsPerPage: "fila por página:",
                displayRows: "de", // 1-10 of 30
            },
            toolbar: {
                search: "Buscar",
                downloadCsv: "Descargar Excel",
                print: "Imprimir",
                viewColumns: "Ver columnas",
                filterTable: "Filtrar tabla",
            },
            filter: {
                all: "Todo",
                title: "FILTROS",
                reset: "por defecto",
            },
            viewColumns: {
                title: "Mostrar columnas",
            },
            selectedRows: {
                text: "fila(s) seleccionada",
            },
        },
        filterType: "checkbox",
        enableNestedDataAccess: ".",
        customToolbarSelect: () => {},
        selectableRows: "none",
        download: false,
        print: false,
        search: true,
        sortOrder: {
            name: "fechaEnrolamiento", // Puedes establecer la columna inicial de ordenación
            direction: "desc", // Puedes establecer la dirección de ordenación inicial
        },
        customBodyRender: (value, tableMeta) => {
            const rowIndex = tableMeta.rowIndex;
            const record = allBeneficiarios[rowIndex];
            const { idBeneficiarioPlan, estado } = record;

            const handleDarDeBaja = async () => {
                const confirmResult = await Swal.fire({
                    title: "¿Estás seguro que deseas dar de baja este beneficiario?",
                    icon: "question",
                    showCancelButton: true,
                    confirmButtonText: "Sí, dar de baja",
                    cancelButtonText: "Cancelar",
                });

                if (confirmResult.isConfirmed) {
                    try {
                        console.log("ACA NO ENTRO JAMAS AMIGO");
                        // Llamar al endpoint de baja_beneficiario con el idBeneficiarioPlan
                        await directus.transport.get(
                            "/crearBeneficiario/darBaja",
                            {
                                params: { idBeneficiarioPlan },
                            }
                        );

                        Swal.fire(
                            "¡Baja exitosa!",
                            "El beneficiario ha sido dado de baja.",
                            "success"
                        );
                        setTimeout(() => {
                            window.location.reload();
                        }, 3000);
                    } catch (error) {
                        console.error(
                            "Error al dar de baja el beneficiario",
                            error
                        );
                        Swal.fire(
                            "Error",
                            "Ocurrió un error al dar de baja el beneficiario.",
                            "error"
                        );
                    }
                }
            };

            return (
                <div>
                    {estado === 1 && (
                        <PersonOffIcon
                            style={{ cursor: "pointer" }}
                            onClick={handleDarDeBaja}
                        />
                    )}
                    {/* Puedes agregar más lógica según el estado aquí */}
                </div>
            );
        },
    };

    useEffect(() => {
        async function getAllBeneficiarios() {
            try {
                setOpenLoading(true);

                const response = await directus.transport.get(
                    "/crearBeneficiario/getDatosBeneficiariosSeguro",
                    {
                        params: { idSeguro },
                    }
                );

                if (response.raw && response.raw.beneficiarios) {
                    const beneficiariosData = response.raw.beneficiarios;
                    setAllBeneficiarios(beneficiariosData);
                    setLoading(false);
                } else {
                    console.error(
                        "No se encontró la propiedad 'beneficiarios' en la respuesta o es nula."
                    );
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setOpenLoading(false);
            }
        }

        if (idSeguro && Array.isArray(idSeguro) && idSeguro.length > 0) {
            getAllBeneficiarios();
        }
    }, [directus, idSeguro]);

    if (isLoading) {
        return <div className="App">Loading...</div>;
    }

    return (
        <div className="">
            <br />
            <Card
                className="p-0 contenedorTabla"
                sx={{ minWidth: 275, padding: 1 }}
            >
                <MUIDataTable
                    title={"Listado de beneficiarios"}
                    data={allBeneficiarios}
                    columns={columns}
                    options={options}
                />
            </Card>
            <Backdrop
                sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={openLoading}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
    );
};
