import { Form, useOutletContext } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useDirectus } from "../../Providers/DirectusProvider";
import Moment from "moment";

import "./PanoramaActual.css";

export const PanoramaActual = (props) => {
    const userLogin = JSON.parse(localStorage.getItem("userLogin"));
    const idVendedor = JSON.parse(localStorage.getItem("idVendedor"));
    const dataPage = useOutletContext();
    var begin = Moment().subtract(30, "days").format("YYYY-MM-DD");

    const { directus } = useDirectus();
    const [nroClosedSales, setNroClosedSales] = useState([]);
    const [nroOpenSales, setNroOpenSales] = useState([]);
    const [valorUf, setValorUf] = useState("");

    //CONSULTAR VENTAS
    useEffect(() => {
        async function getClosedSales() {
            if (userLogin.role.name === "admin_vendedor") {
                const datos = await directus.items("venta").readByQuery({
                    fields: ["*"],
                    filter: {
                        estado: 1,
                        fechaVenta: {
                            _gte: begin
                        },
                    },
                    meta: "filter_count",
                });
                setNroClosedSales(datos.meta.filter_count);
            } else if (userLogin.role.name === "vendedor") {
                const datos = await directus.items("venta").readByQuery({
                    fields: ["*"],
                    filter: {
                        estado: 1,
                        fechaVenta: {
                            _gte: begin
                        },
                        idVendedor: idVendedor,
                    },
                    meta: "filter_count",
                });
                setNroClosedSales(datos.meta.filter_count);
            }
        }
        getClosedSales();

        async function getOpenSales() {
            if (userLogin.role.name === "admin_vendedor") {
                //const vendedorAdmin = userLogin.role.name === "admin_vendedor" ?
                const datos = await directus.items("venta").readByQuery({
                    fields: ["*"],
                    filter: {
                        estado: 0,
                        fechaVenta: {
                            _gte: begin
                        }
                    },
                    meta: "filter_count",
                });
                setNroOpenSales(datos.meta.filter_count);
            } else if (userLogin.role.name === "vendedor") {
                //const vendedorAdmin = userLogin.role.name === "admin_vendedor" ?
                const datos = await directus.items("venta").readByQuery({
                    fields: ["*"],
                    filter: {
                        estado: 0,
                        fechaVenta: {
                            _gte: begin
                        },
                        idVendedor: idVendedor,
                    },
                    meta: "filter_count",
                });
                setNroOpenSales(datos.meta.filter_count);
            }
        }
        getOpenSales();

        async function getValorUf() {
            const datos = await directus.items("valor_uf").readByQuery({
                fields: ["*"],
                filter: {
                    valor: {
                        _nnull: true,
                    },
                    fecha: Moment().format("YYYY-MM-DD")
                },
                sort: ['fecha']
            });
            setValorUf(datos.data[0].valor);
        }
        getValorUf();
    }, [directus, userLogin.role.name, begin, idVendedor]);

    return (
        <div className="container-page">
            <Card sx={{ minWidth: 275, padding: 1 }}>
                <ListItemText primary="Indicadores económicos" />
                <Divider light />
                <Button variant="contained" disableElevation>
                    {"UF.  $" + valorUf}
                </Button>
            </Card>
            <br />
            <Card sx={{ minWidth: 275, padding: 1 }}>
                <ListItemText primary="Indicadores" />
                <Divider light />
                <div className="contenedorCard">
                    <Card
                        sx={{
                            minWidth: 150,
                            maxWidth: 200,
                            maxHeight: 120,
                            padding: 1,
                            marginTop: 1,
                            backgroundColor: "#8bc34a",
                        }}
                    >
                        <CardContent className="contenidoCard">
                            <Box className="dataCard" component="span" sx={{}}>
                                <div className="">
                                    <CheckCircleIcon className="bigIcon" />
                                </div>
                                <div className="dataCardText">
                                    <ListItemText
                                        className="bigIcon"
                                        primary={nroClosedSales}
                                    />
                                    <ListItemText
                                        className=""
                                        primary="N° de ventas"
                                    />
                                    <ListItemText
                                        className=""
                                        primary="cerradas Mes"
                                    />
                                </div>
                            </Box>
                        </CardContent>
                    </Card>
                    <Card
                        sx={{
                            minWidth: 150,
                            maxWidth: 200,
                            maxHeight: 120,
                            padding: 1,
                            marginTop: 1,
                            backgroundColor: "#ff1744",
                        }}
                    >
                        <CardContent className="contenidoCard">
                            <Box className="dataCard" component="span" sx={{}}>
                                <div className="">
                                    <ShoppingCartIcon className="bigIcon" />
                                </div>
                                <div className="dataCardText">
                                    <ListItemText
                                        className="bigIcon"
                                        primary={nroOpenSales}
                                    />
                                    <ListItemText
                                        className=""
                                        primary="N° de ventas"
                                    />
                                    <ListItemText
                                        className=""
                                        primary="Pendt. Mes"
                                    />
                                </div>
                            </Box>
                        </CardContent>
                    </Card>
                </div>
            </Card>
        </div>
    );
};
