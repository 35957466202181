import React, { useState, useEffect } from "react";
import { InputText } from "./InputText";
import { useDirectus } from "../../Providers/DirectusProvider";

export const InputRut = ({
    disabled,
    register,
    name,
    errors,
    maxLength,
    requerido,
    descripcion,
    value,
    onChange,
    titular = false,
    tieneValorModal,
    setTieneValorModal,
    datos,
    idPlan = null,
    opcional = false
}) => {
    const [rutFormateado, setRutFormateado] = useState("");

    const { directus } = useDirectus();

    const calculoDV = (T) => {
        let M = 0,
            S = 1;
        for (; T; T = Math.floor(T / 10))
            S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
        return S ? S - 1 : "k";
    };

    const revisaValido = (rut, dv) => {
        if (rut) {
            return calculoDV(rut).toString().toLowerCase() === dv.toLowerCase();
        } else {
            return false;
        }
    };

    const limpiarRut = (e) => {
        setRutFormateado("");
        const limpio = e.target.value.replace(/[^(0-9|k)]/gi, "");
        let dv = limpio[limpio.length - 1];
        let rut = limpio.slice(0, -1);
        if (limpio.length >= 1) {
            setRutFormateado(`${rut}-${dv}`);
        }
        e.target.value = limpio;
        if (onChange) {
            setTieneValorModal(false);
            onChange(e);
        }
    };

    const validarRut = (rutIngresado) => {
        if (typeof rutIngresado === "string") {
            if (!rutIngresado || rutIngresado === "-" || rutIngresado === "") {
                return true;
            } else {
                if (value !== "") {
                    rutIngresado = value;
                }
                const limpio = rutIngresado.replace(/[^(0-9|k)]/gi, "");
                let dv = limpio[limpio.length - 1];
                let rut = limpio.slice(0, -1);
                return revisaValido(rut, dv);
            }
        }
        return false;
    };

    const checkRut = async (rutIngresado) => {
        if (rutIngresado && !disabled && !titular) {
            console.log("idPlan");
            console.log(idPlan);
            if (validarRut(rutIngresado) && idPlan) {
                const consulta = await directus.transport.get(
                    "/crearBeneficiario/check_rut",
                    {
                        params: {
                            rut: rutIngresado,
                            idPlan: idPlan,
                        },
                    }
                );
                return consulta.raw;
            } else {
                return true;
            }
        }
        return true;
    };

    const checkRutTitular = async (rutIngresado) => {
        console.log("rutIngresado");
        console.log(rutIngresado);
        if (titular && !(!rutIngresado || rutIngresado === "-" || rutIngresado === "")) {
            if (validarRut(rutIngresado)) {
                const consulta = await directus.transport.get(
                    "/flow/check_rut",
                    {
                        params: {
                            rut: rutIngresado,
                            idBeneficiario: null, // Ajusta esto según tus necesidades
                        },
                    }
                );
                return !consulta.raw;
            } else {
                return true;
            }
        }
        console.log('SIN CHEKEAR TITULAR');
        return true;
    };

    return (
        <InputText
            disabled={disabled}
            register={register}
            descripcion={descripcion}
            rules={{
                required: opcional ? undefined : "Campo obligatorio",
                validate: {
                    validateRut: (v) => validarRut(v) || "RUT incorrecto",
                    RutExist: async (v) =>
                        (await checkRut(v)) || "Ya se encuentra registrado",
                    RutExistTitular: async (v) =>
                        (await checkRutTitular(v)) || "El titular debe existir",
                },
            }}
            type="text"
            placeholder="Ingrese Rut"
            name={name}
            onChange={limpiarRut}
            value={
                !titular
                    ? tieneValorModal
                        ? datos.rut
                        : rutFormateado
                    : tieneValorModal
                    ? datos.titular
                    : rutFormateado
            }
            errors={errors[name]}
            maxLength={maxLength}
            requerido={requerido}
            onPaste={(e) => {
                e.preventDefault();
                return false;
            }}
            onCopy={(e) => {
                e.preventDefault();
                return false;
            }}
        />
    );
};
