import React, { useState } from "react";
import { Form } from "react-bootstrap";

export default function CorreoInput({ onChange, onIsValid, placeholder }) {
  const [correo, setCorreo] = useState('');

  const validarCorreo = (value) => {
    setCorreo(value);
    onChange(value);
    const emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;    
    if(emailRegex.test(value)){
      onIsValid(true);    
    }
  };

  return <Form.Control size="sm" value={correo} onChange={(event) => validarCorreo(event.target.value)} placeholder={placeholder} />;
}
