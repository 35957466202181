import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import { useDirectus } from "../../Providers/DirectusProvider";
import { InputText } from "./InputText";
import { InputEmail } from "./InputEmail";

import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { InputSelect } from "./InputSelect";

import "./SalesForm.css";

export const QuoteForm = ({
    idVendedor,
    handleCloseLoading,
    handleToggleLoading,
}) => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        setFocus,
        clearErrors,
    } = useForm({ mode: "all" });
    const { directus } = useDirectus();
    const [planes, setPlanes] = useState([]);
    const [razones, setRazones] = useState([]);
    /* variables cargas */


    const [datos, setDatos] = useState({
        nombres: "",
        telefono: "",
        email: "",
        email_confirmacion: "",
        plan: "",
        razon: "",
        comentario: ""
    });




    const onSubmitDatosBeneficario = (data) => enviarCotizacion();

    const HandleInputChange = (event) => {
        if (document.activeElement.name === event.target.name) {
            setDatos({
                ...datos, //una copia para no ir borrando
                [event.target.name]: event.target.value,
            });
        } else {
            datos[event.target.name] = "";
        }
    };

    const forzarError = (input, error) => {
        //datos[input] = datos[input];
        setFocus(input);
        setError(input, { type: "custom", message: error });
    };

    const enviarCotizacion = useCallback(async () => {
        handleToggleLoading();
        let cotizacion = false;
        try {
            cotizacion = await directus.transport.post(
                "/flow/beneficiario/cotizacion",
                { datos, idVendedor }
            );
        } catch (error) {
            let inputError = error.errors[0].extensions.field;
            handleCloseLoading();
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Ocurrio un error al intentar registrar cotizacion.",
            }).then((result) => {
                if (!result.isDenied) {
                    forzarError(
                        inputError,
                        inputError + " ya se encuentra registrado"
                    );
                }
            });

            cotizacion = false;
        }
        if (cotizacion.raw.status == 401) {
            handleCloseLoading();
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Ocurrio un error al intentar registrar cotizacion.",
            }).then((result) => {
                //
            });

            cotizacion = false;
        } else {
            handleToggleLoading();

            handleCloseLoading();
            Swal.fire({
                customClass: {
                    title: "titulo-venta",
                },
                title: "¡Cotización enviada con exito al correo " + datos.email,
                icon: "success",
                confirmButtonText: "Aceptar",
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/asistente-ventas", {
                        success: true,
                    });
                } else {
                    navigate("/asistente-ventas", {
                        success: true,
                    });
                }
            });
        }
    }, [datos, directus, forzarError, setError]);

    //CONSULTAR BENEFICIARIO
    useEffect(() => {
        async function getPlanes() {
            const res = await directus.items("plan").readByQuery({
                fields: ["*"],
                filter: {
                    idSeguro: 1,
                    activo: true
                },
            });
            setPlanes(res.data);
        }
        getPlanes();
        async function getRazon() {
            const res = await directus
                .items("razonCotizacion")
                .readByQuery({ fields: ["*"] });
            setRazones(res.data);
        }

        getRazon();
    }, [directus]);

    let listPlanes = planes.map((p) => (
        <option key={p.idPlan} value={p.idPlan} sku={p.sku}>
            {p.nombre}
        </option>
    ));
    let listRazon = razones.map((r) => (
        <option key={r.idRazonCotizacion} value={r.idRazonCotizacion}>
            {r.descripcion}
        </option>
    ));

    //

    return (
        <div className="">
            <Card sx={{ minWidth: 275, padding: 1 }}>
                <ListItemText primary="Datos de beneficiario" />
                <Divider light />
                <form
                    onSubmit={handleSubmit(onSubmitDatosBeneficario)}
                    autoComplete="false"
                    className="formularioSale"
                >
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputSelect
                                register={register}
                                descripcion="Seleccione plan a ofrecer"
                                rules={{
                                    required: "campo obligatorio",
                                }}
                                id="plan"
                                name="plan"
                                option="Seleccione un plan"
                                options={listPlanes}
                                errors={errors.plan}
                                value={datos.plan}
                                onChange={HandleInputChange}
                                infoPlan={datos.plan}
                            />
                        </Box>
                    </div>

                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Nombres"
                                rules={{
                                    required: "campo obligatorio",
                                    validate: (v) =>
                                        !!v.trim() || "campo obligatorio",
                                }}
                                type="text"
                                placeholder="Ingrese Nombre"
                                name="nombres"
                                errors={errors.nombres}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                maxLength={150}
                                value={datos.nombres}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputEmail
                                register={register}
                                name="email"
                                errors={errors}
                                onChange={HandleInputChange}
                                datos={datos}
                                clearErrors={clearErrors}
                                descripcion="Email"
                                value={datos.email}
                                required={true}
                                desdeCotizacion={true}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputEmail
                                register={register}
                                name="email_confirmacion"
                                errors={errors}
                                onChange={HandleInputChange}
                                datos={datos}
                                confirmar={true}
                                clearErrors={clearErrors}
                                onPaste={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                onCopy={(e) => {
                                    e.preventDefault();
                                    return false;
                                }}
                                descripcion="Confirmar Email"
                                value={datos.email_confirmacion}
                                required={true}
                                desdeCotizacion={true}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Teléfono"
                                rules={{
                                    validate: (v) => {
                                        if (!v || v.trim() === "") {
                                            // El campo está vacío, no aplicar validación de longitud.
                                            return true;
                                        } else {
                                            return (
                                                v.length > 8 ||
                                                "Número de teléfono incorrecto"
                                            );
                                        }
                                    },
                                }}
                                type="text"
                                placeholder="Ingrese teléfono"
                                name="telefono"
                                errors={errors.telefono}
                                onChange={HandleInputChange}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }}
                                value={datos.telefono}
                                maxLength={11}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputSelect
                                register={register}
                                descripcion="Razón de cotización"
                                rules={{
                                    required: "campo obligatorio",
                                }}
                                id="razon"
                                name="razon"
                                option="Seleccione una razón"
                                options={listRazon}
                                errors={errors.razon}
                                value={datos.razon}
                                onChange={HandleInputChange}
                            />
                        </Box>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <InputText
                                register={register}
                                descripcion="Comentario Abierto"
                                type="textArea" // Cambiado a "textArea" para que sea un textarea
                                placeholder="Agrega un comentario extra"
                                name="comentario"
                                errors={errors.comentario}
                                onChange={HandleInputChange}
                                value={datos.comentario}
                            />
                        </Box>
                    </div>

                    <div className="col-md-12 btn-acciones">
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="success"
                            >
                                Enviar Cotización
                            </Button>
                        </Box>
                    </div>
                </form>
            </Card>
        </div>
    );
};
