import React, {} from "react";
import { Navigate, useOutlet } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";

export const HomeLayout = () => {
    const { user, rolUsuario } = useAuth();
    const outlet = useOutlet();

    if (user) {
        console.log(user);
        console.log("user");
        if (rolUsuario === "admin_beneficiarios") {
            return <Navigate to="/admin-beneficiarios" replace />;
        } else {
            return <Navigate to="/parorama-actual" replace />;
        }
    }

    return <div>{outlet}</div>;
};
