import React from "react";
import { InputText } from "./InputText";
import { InputRut } from "./InputRut";
import { InputSelect } from "./InputSelect";
import { InputDate } from "./InputDate";
import { InputEmail } from "./InputEmail";

export const Cargas = ({
    id,
    register,
    errors,
    listGenero,
    listParentesco,
    cargas,
    setCargas,
    datosBeneficiarioTitular,
    clearErrors,
    datos,
}) => {
    const HandleInputChangeCarga = (event) => {
        cargas.forEach((carga) => {
            if (event.target.name.replace("nombres_", "") == carga.id) {
                if (id != 1 && event.target.value === "") {
                    // Eliminamos la propiedad "nombres" del objeto carga
                    delete carga.nombres;
                } else {
                    carga.nombres = event.target.value;
                }
            }
            if (event.target.name.replace("primer_apellido_", "") == carga.id) {
                if (id != 1 && event.target.value === "") {
                    // Eliminamos la propiedad "nombres" del objeto carga
                    delete carga.primer_apellido;
                } else {
                    carga.primer_apellido = event.target.value;
                }
                carga.primer_apellido = event.target.value;
            }
            if (
                event.target.name.replace("segundo_apellido_", "") == carga.id
            ) {
                carga.segundo_apellido = event.target.value;
            }
            if (event.target.name.replace("rut_", "") == carga.id) {
                carga.rut = event.target.value;
            }
            if (event.target.name.replace("genero_", "") == carga.id) {
                carga.genero = event.target.value;
            }
            if (event.target.name.replace("fechaNacimiento_", "") == carga.id) {
                carga.fechaNacimiento = event.target.value;
            }
            if (event.target.name.replace("email_", "") == carga.id) {
                carga.email = event.target.value;
            }
            if (
                event.target.name.replace("email_confirmacion_", "") == carga.id
            ) {
                carga.email_confirmacion = event.target.value;
            }
            if (event.target.name.replace("contacto_", "") == carga.id) {
                carga.contacto = event.target.value;
            }
            if (event.target.name.replace("telefono_", "") == carga.id) {
                carga.telefono = event.target.value;
            }
            if (event.target.name.replace("parentesco_", "") == carga.id) {
                carga.parentesco = event.target.value;
            }
        });
        setCargas([...cargas]);
    };


    const validarDataSoloPrimeraCarga = (v) => {
        if (id === 1 ) {//primera carga
            return !!v.trim();
        }else if(id != 1){
            if(typeof cargas[id - 1]["nombres"] != "undefined" || typeof cargas[id - 1]["primer_apellido"] != "undefined"){
                return !!v.trim();
            }
        }
        return true;
    };

    return (
        <>
            <div key={id} className="conteiner-cargar">
                <h6>Información carga N° {id}</h6>
                <div className="row">
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <InputRut
                            descripcion="Rut"
                            register={register}
                            name={"rut_" + id}
                            errors={errors}
                            onChange={HandleInputChangeCarga}
                            maxLength={10}
                            requerido={true}
                            datos={datosBeneficiarioTitular}
                            esCarga={true}
                            cargas={cargas}
                            value={cargas["rut_" + id]}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <InputText
                            register={register}
                            descripcion="Nombres *"
                            rules={{
                                /*required: "campo obligatorio",*/
                                validate: {
                                    primeraCarga: (v) => 
                                        validarDataSoloPrimeraCarga(v) || "Campo obligatorio",
                                },
                            }}
                            type="text"
                            placeholder="Ingrese Nombre"
                            name={"nombres_" + id}
                            errors={errors["nombres_" + id]}
                            onChange={HandleInputChangeCarga}
                            onKeyPress={(event) => {
                                if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            maxLength={150}
                            value={cargas["nombres_" + id]}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <InputText
                            register={register}
                            descripcion="Primer Apellido *"
                            rules={{
                                /*required: "campo obligatorio",*/
                                validate: {
                                    primeraCarga: (v) => 
                                        validarDataSoloPrimeraCarga(v) || "Campo obligatorio",
                                },
                            }}
                            type="text"
                            placeholder="Ingrese primer apellido"
                            name={"primer_apellido_" + id}
                            errors={errors["primer_apellido_" + id]}
                            onChange={HandleInputChangeCarga}
                            onKeyPress={(event) => {
                                if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            requerido={true}
                            value={cargas["primer_apellido_" + id]}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <InputText
                            register={register}
                            descripcion="Segundo Apellido"
                            rules={
                                {
                                    /*required: "campo obligatorio",
                                validate: (v) =>
                                    !!v.trim() || "campo obligatorio",*/
                                }
                            }
                            type="text"
                            placeholder="Ingrese segundo apellido"
                            name={"segundo_apellido_" + id}
                            errors={errors["segundo_apellido_" + id]}
                            confirmar={true}
                            onChange={HandleInputChangeCarga}
                            onKeyPress={(event) => {
                                if (!/[A-zÀ-ú\s]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            requerido={true}
                            value={cargas["segundo_apellido_" + id]}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <InputSelect
                            register={register}
                            descripcion="Genero"
                            rules={
                                {
                                    /*required: "campo obligatorio",*/
                                }
                            }
                            id={"genero_" + id}
                            name={"genero_" + id}
                            option="Seleccione un genero"
                            options={listGenero}
                            errors={errors["genero_" + id]}
                            onChange={HandleInputChangeCarga}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <InputDate
                            register={register}
                            descripcion="Fecha de Nacimiento"
                            rules={
                                {
                                    /*required: "campo obligatorio"*/
                                }
                            }
                            type="date"
                            name={"fechaNacimiento_" + id}
                            errors={errors["fechaNacimiento_" + id]}
                            onChange={HandleInputChangeCarga}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <InputEmail
                            register={register}
                            name={"email_" + id}
                            errors={errors}
                            onChange={HandleInputChangeCarga}
                            requerido={true}
                            carga={true}
                            cargas={cargas}
                            datos={datos}
                            clearErrors={clearErrors}
                            idCarga={id}
                            descripcion="Email"
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <InputEmail
                            register={register}
                            name={"email_confirmacion_" + id}
                            errors={errors}
                            onChange={HandleInputChangeCarga}
                            requerido={true}
                            carga={true}
                            cargas={cargas}
                            datos={datos}
                            clearErrors={clearErrors}
                            idCarga={id}
                            confirmar={true}
                            descripcion="Confirmar Email"
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <InputSelect
                            register={register}
                            descripcion="Parentesco"
                            rules={
                                {
                                    /*required: "campo obligatorio",*/
                                }
                            }
                            id={"parentesco_" + id}
                            name={"parentesco_" + id}
                            option="Seleccione un parentesco"
                            options={listParentesco}
                            errors={errors["parentesco_" + id]}
                            onChange={HandleInputChangeCarga}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <InputText
                            register={register}
                            descripcion="Número de celular"
                            rules={{
                                /*required: "campo obligatorio",*/
                                minLength: {
                                    value: 4,
                                    message: "Debe tener al menos 4 letras",
                                },
                            }}
                            type="text"
                            placeholder="Ingrese número de celular"
                            name={"contacto_" + id}
                            errors={errors["contacto_" + id]}
                            onChange={HandleInputChangeCarga}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
                        <InputText
                            register={register}
                            descripcion="Número de teléfono"
                            rules={
                                {
                                    /*required: "campo obligatorio",
                                minLength: {
                                    value: 4,
                                    message: "Debe tener al menos 4 letras",
                                },*/
                                }
                            }
                            type="text"
                            placeholder="Ingrese número de teléfono"
                            name={"telefono_" + id}
                            errors={errors["telefono_" + id]}
                            onChange={HandleInputChangeCarga}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </div>
                </div>
                <hr></hr>
            </div>
        </>
    );
};
