import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { useDirectus } from "../../Providers/DirectusProvider";
export const InputSelect = ({
  register,
  descripcion,
  rules,
  id,
  name,
  option,
  options,
  errors,
  onChange,
  requerido,
  value,
  infoPlan = null,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { directus } = useDirectus();
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const [plan, setPlan] = useState([]);

  const openDetallePlan = useCallback((plan) => {
    Swal.fire({
      width: 1000,
      customClass: {
        title: "mas-info",
        confirmButton: "button-plan btn-principal siguiente-info",
      },
      title: "<strong>" + plan.nombre + "</strong>",
      html: plan.descripcion,
      showCloseButton: true,
      focusConfirm: true,
    });
  });

  useEffect(() => {
    async function getPlan() {
      if(infoPlan){
        const res = await directus.items("plan").readByQuery({
          fields: ["*"],
          filter: {
            idPlan: infoPlan,
          },
        });
        setPlan(res.data[0]);
      }
      
    }
    getPlan();
  }, [directus, infoPlan]);

  const isOpen = Boolean(anchorEl);
  return (
    <>
      <label className="d-flex flex-row" htmlFor={name}>
        <span className="dot">•</span> {descripcion}
        {requerido ? <span className="requerido"> *</span> : ""}
        {infoPlan ? (
          <div>
            <InfoIcon
              className="InfoIcon"
              aria-owns={isOpen ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              onClick={() => openDetallePlan(plan)}
            ></InfoIcon>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={isOpen}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{ p: 1 }}>Ver información del plan</Typography>
            </Popover>
          </div>
        ) : (
          ""
        )}
      </label>
      <select
        {...register(name, rules)}
        className={`form-control ${errors && "form-error"}`}
        id={id}
        name={name}
        onChange={onChange}
        value={value}
      >
        <option value={""}>{option}</option>
        {options}
      </select>
      {errors && <small className="text-danger-seguro">{errors.message}</small>}
    </>
  );
};
