import React, { useState, useEffect } from "react";
import { InputText } from "./InputText";
import { useDirectus } from "../../Providers/DirectusProvider";

export const InputDni = ({
    disabled,
    register,
    name,
    errors,
    maxLength,
    requerido,
    descripcion,
    value,
    onChange,
    titular = false,
    tieneValorModal,
    setTieneValorModal,
    datos,
    idPlan = null,
    idPais = null,
    opcional = false,
}) => {
    const { directus } = useDirectus();

    const checkDni = async (dniIngresado) => {
        if (dniIngresado && !disabled && !titular) {

            if (idPlan && idPais) {

                const consulta = await directus.transport.get(
                    "/crearBeneficiario/check_dni",
                    {
                        params: {
                            dni: dniIngresado,
                            idPlan: idPlan,
                            idPais: idPais,
                        },
                    }
                );
                return consulta.raw;
            } else {
                return true;
            }
        }
        return true;
    };

    const checkDniTitular = async (dniIngresado) => {
        if (dniIngresado && titular) {
            if (idPlan && idPais) {

                const consulta = await directus.transport.get(
                    "/crearBeneficiario/check_dni",
                    {
                        params: {
                            dni: dniIngresado,
                            titular: true,
                            idPlan: idPlan,
                            idPais: idPais,
                        },
                    }
                );
                return !consulta.raw;
            } else {
                return true;
            }
        }
        return true;
    };

    return (
        <InputText
            disabled={disabled}
            register={register}
            descripcion={descripcion}
            rules={{
                required: opcional ? undefined : "Campo obligatorio",
                validate: {
                    DnitExist: async (v) =>
                        (await checkDni(v)) || "Ya se encuentra registrado",
                    RutExistTitular: async (v) =>
                        (await checkDniTitular(v)) ||
                        "El titular debe existir",
                    alphanumeric: (v) =>
                        opcional ||
                        /^\d+$/.test(v) ||
                        "Solo se permiten números",
                    lengthValidation: (v) => {
                        if (idPais == 4) {
                            return (
                                opcional ||
                                v.length == 9 ||
                                "La longitud debe ser 9 para el país Perú"
                            );
                        } else if (idPais == 2) {
                            return (
                                opcional ||
                                v.length == 10 ||
                                "La longitud debe ser 10 para el país Colombia"
                            );
                        } else {
                            return (
                                opcional ||
                                v.length == 10 ||
                                "La longitud maxima es de 10 digitos"
                            );
                        }
                    },
                },
            }}
            type="text"
            placeholder="Ingrese DNI"
            name={name}
            onChange={onChange}
            value={
                !titular
                    ? tieneValorModal
                        ? datos.dni
                        : value
                    : tieneValorModal
                    ? datos.titular
                    : value
            }
            errors={errors[name]}
            requerido={requerido}
        />
    );
};
