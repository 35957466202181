import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

import { useDirectus } from "../../Providers/DirectusProvider";
import { SalesFormExtendedEdit } from "./SalesFormExtendedEdit";
import { CargasEdit } from "./CargasEdit";

import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import Moment from "moment";

import "./SalesForm.css";

export const SalesFormEditOnlyCargas = ({
    newClientRut,
    btnDisabled,
    handleCloseLoading,
    handleToggleLoading,
    idBeneficiarioEdit = null,
    dataBene = null,
    dataPlan = null,
    ventaRemota = false,
    setNewClientRut = false,
    cantCargasInicial = null,
    dataCargas = null,
}) => {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        setError,
        formState: { errors },
        setFocus,
        clearErrors,
        setValue,
    } = useForm({ mode: "all" });
    const { directus } = useDirectus();
    const [planes, setPlanes] = useState([]);
    const [generos, setGeneros] = useState([]);
    const [regiones, setRegiones] = useState([]);
    const [comunas, setComunas] = useState([]);
    const [nombreRegion, setNombreRegion] = useState([]);
    const [parentescos, setParentescos] = useState([]);
    const [formExtended, SetFormExtended] = useState([]);
    const [idBeneficiario, setIdBeneficiario] = useState(null);
    /* variables cargas */
    const [cargas, setCargas] = useState([]);
    const [cantidadCargas, setCantidadCargas] = useState(0);
    const [quitarCarga, setQuitarCarga] = useState(false);
    const [cantCargas, setCantCargas] = useState(0);
    const [textButton, setTextButton] = useState("Enviar Formulario");
    const [cargasEliminadas, SetCargasEliminadas] = useState(false);
    const [planInicial, setPlanInicial] = useState(false);
    const [edicionDatosRemota, setEdicionDatosRemota] = useState(true);
    const [asignarcionCompletada, setAsignarcionCompletada] = useState(false);

    const [datos, setDatos] = useState({
        rut: "",
        nombres: "",
        primer_apellido: "",
        segundo_apellido: "",
        genero: "",
        fechaNacimiento: "",
        celular: "",
        telefono: "",
        email: "",
        email_confirmacion: "",
        direccion: "",
        nro_direccion: "",
        referencia: "",
        region: "",
        comuna: "",
        idBeneficiario: "",
        plan: "",
    });

    const reglaEdadMin = (date) => {
        var edadMinima = Moment().subtract(18, "years").format("YYYY-MM-DD");
        return date < edadMinima;
    };
    const reglaEdadMax = (date) => {
        var edadMaxima = Moment().subtract(65, "years").format("YYYY-MM-DD");
        return date > edadMaxima;
    };

    const continuarFormulario = () => {
        SetFormExtended([]);
        SetFormExtended([{ id: 1 }]);
    };

    //AGREGAR AL FORMULARIO LAS CARGAS
    const agregarCarga = useCallback(
        (cantidadCarga = null, sumandoUnaCarga = false) => {
            if (cantidadCarga > 0) {
                let cantCargas = null;
                setQuitarCarga(false);
                setCantidadCargas(0);
                cantCargas = cantidadCarga;

                if (cantCargas && cantCargas > 0) {
                    setCantidadCargas(cantCargas);
                    if (sumandoUnaCarga) {
                        setCargas([
                            ...cargas,
                            {
                                id: 3,
                                idBeneCarga: "",
                                fechaNacimiento: "",
                                email: "",
                                email_confirmacion: "",
                            },
                        ]);
                    } else {
                        if (cantCargas == 2) {
                            setCargas([
                                {
                                    id: 1,
                                    idBeneCarga: "",
                                    fechaNacimiento: "",
                                    email: "",
                                    email_confirmacion: "",
                                },
                                {
                                    id: 2,
                                    idBeneCarga: "",
                                    fechaNacimiento: "",
                                    email: "",
                                    email_confirmacion: "",
                                },
                            ]);
                        } else {
                            setCargas([
                                {
                                    id: 1,
                                    idBeneCarga: "",
                                    fechaNacimiento: "",
                                    email: "",
                                    email_confirmacion: "",
                                },
                                {
                                    id: 2,
                                    idBeneCarga: "",
                                    fechaNacimiento: "",
                                    email: "",
                                    email_confirmacion: "",
                                },
                                {
                                    id: 3,
                                    idBeneCarga: "",
                                    fechaNacimiento: "",
                                    email: "",
                                    email_confirmacion: "",
                                },
                            ]);
                        }
                    }
                }
            } else {
            }
        },
        [setQuitarCarga, setCargas, setCantidadCargas, cargas]
    );

    const onSubmitDatosBeneficario = (data) => actualizarDataCargas();

    const HandleInputChange = (event) => {
        if (datos.rut == "") {
            datos["rut"] = newClientRut;
        }
        if (document.activeElement.name === event.target.name) {
            setDatos({
                ...datos, //una copia para no ir borrando
                [event.target.name]: event.target.value,
            });
        } else {
            datos[event.target.name] = "";
        }
    };

    const forzarError = (input, error) => {
        //datos[input] = datos[input];
        setFocus(input);
        setError(input, { type: "custom", message: error });
    };

    const clearRut = (valueRut) => {
        if (valueRut) {
            const limpio = valueRut.replace(/[^(0-9|k)]/gi, "");
            let M = 0,
                S = 1;
            for (; valueRut; valueRut = Math.floor(valueRut / 10))
                S = (S + (valueRut % 10) * (9 - (M++ % 6))) % 11;
            let dv = S ? S - 1 : "k";
            return `${limpio}-${dv}`;
        } else {
            return "";
        }
    };

    const actualizarDataCargas = useCallback(async () => {
        handleToggleLoading();
        let actualizarCargas = false;
        try {
            actualizarCargas = await directus.transport.post(
                "/flow/beneficiario/actualizarCargas",
                { datos, cargas, formExtended }
            );
        } catch (error) {
            let inputError = error.errors[0].extensions.field;
            handleCloseLoading();
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text:
                    "El " +
                    inputError +
                    " ingresado ya se encuentra registrado.",
            }).then((result) => {
                if (!result.isDenied) {
                    forzarError(
                        inputError,
                        inputError + " ya se encuentra registrado"
                    );
                }
            });
        }

        if (actualizarCargas) {
            handleCloseLoading();
            Swal.fire({
                customClass: {
                    title: "titulo-venta",
                },
                title: "Datos del beneficiarios carga guardados correctamente ",
                icon: "success",
                confirmButtonText: "Aceptar",
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate("/ventas-completas", {
                        success: true,
                    });
                } else {
                    navigate("/ventas-completas", {
                        success: true,
                    });
                }
            });
        }
    }, [datos, directus, forzarError, setError]);

    //CONSULTAR BENEFICIARIO
    useEffect(() => {
        agregarCarga(cantCargasInicial);
        async function getGeneros() {
            const res = await directus
                .items("genero")
                .readByQuery({ fields: ["*"] });
            setGeneros(res.data);
        }
        async function getParentescos() {
            const res = await directus
                .items("parentesco")
                .readByQuery({ fields: ["*"] });
            setParentescos(res.data);
        }
        getGeneros();
        getParentescos();
    }, [directus]);

    useEffect(() => {
        async function getComunas() {
            if (datos.region != "") {
                const res = await directus.items("comuna").readByQuery({
                    fields: ["*"],
                    filter: {
                        idRegion: datos.region,
                        nombre: {
                            _neq: nombreRegion,
                        },
                    },
                    sort: ["nombre"],
                });
                setComunas(res.data);
            }
        }
        getComunas();
    }, [directus, datos, nombreRegion]);

    useEffect(() => {
        async function asignarValoresEdit() {
            if (idBeneficiarioEdit && !asignarcionCompletada) {
                setAsignarcionCompletada(true);
                setCantCargas(cantCargasInicial);
                setIdBeneficiario(idBeneficiarioEdit);
                setTextButton("Actualizar Datos");
                continuarFormulario();
                //asignar plan inicial
                setPlanInicial(dataPlan.idPlan);
                //asignar valores
                if (ventaRemota || idBeneficiarioEdit) {
                    //cambiar info plan
                    datos.plan = dataPlan.idPlan;
                    setValue("plan", dataPlan.idPlan);
                    datos.nombres = dataBene.nombres;
                    datos.primer_apellido = dataBene.primerApellido;
                    datos.segundo_apellido = dataBene.segundoApellido;
                    datos.email = dataBene.email;
                    datos.email_confirmacion = dataBene.email;
                    datos.rut = clearRut(dataBene.rut);
                    setNewClientRut(clearRut(dataBene.rut));
                    datos.idBeneficiario = dataBene.idBeneficiario;

                    setValue("nombres", dataBene.nombres);
                    setValue("primer_apellido", dataBene.primerApellido);
                    setValue("segundo_apellido", dataBene.segundoApellido);
                    setValue("email", dataBene.email);
                    setValue("email_confirmacion", dataBene.email);
                    setValue("rut", clearRut(dataBene.rut));
                    //agregar datos adicionales y cargas
                    if (dataBene.idGenero) {
                        datos.genero = dataBene.idGenero.idGenero;
                        datos.celular = dataBene.celular;
                        datos.telefono = dataBene.telefono;
                        datos.direccion = dataBene.direccion.split(",")[0];
                        datos.nro_direccion = dataBene.direccion.split(",")[1];
                        datos.referencia = dataBene.direccion.split(",")[2];
                        datos.fechaNacimiento = Moment(
                            dataBene.fechaNacimiento
                        ).format("YYYY-MM-DD");

                        setValue("genero", dataBene.idGenero.idGenero);
                        setValue("celular", dataBene.celular);
                        setValue("telefono", dataBene.telefono);
                        setValue("direccion", dataBene.direccion.split(",")[0]);
                        setValue(
                            "nro_direccion",
                            dataBene.direccion.split(",")[1]
                        );
                        setValue(
                            "referencia",
                            dataBene.direccion.split(",")[2]
                        );
                        //setFechaMui(Moment(dataBene.fechaNacimiento));
                        setValue(
                            "fechaNacimiento",
                            Moment(dataBene.fechaNacimiento).format(
                                "YYYY-MM-DD"
                            )
                        );
                        const resComuna = await directus
                            .items("comuna")
                            .readByQuery({
                                fields: ["*"],
                                filter: {
                                    codComuna: dataBene.comuna.codComuna,
                                },
                                limit: 1,
                            });
                        const resRegion = await directus
                            .items("region")
                            .readByQuery({
                                fields: ["*"],
                                filter: {
                                    idRegion: resComuna.data[0].idRegion,
                                },
                                limit: 1,
                            });
                        setNombreRegion(resRegion.data[0].nombre);
                        const resComunas = await directus
                            .items("comuna")
                            .readByQuery({
                                fields: ["*"],
                                filter: {
                                    idRegion: resComuna.data[0].idRegion,
                                    nombre: {
                                        _neq: resRegion.data[0].nombre,
                                    },
                                },
                                sort: ["nombre"],
                            });
                        datos.region = resComuna.data[0].idRegion;
                        setValue("region", resComuna.data[0].idRegion);
                        setComunas(resComunas.data);
                        setTimeout(() => {
                            datos.comuna = dataBene.comuna.codComuna;
                            setValue("comuna", dataBene.comuna.codComuna);
                        }, 250);

                        if (!cargasEliminadas && planInicial != datos.plan) {
                            cargas.forEach((carga) => {
                                console.log('ingresando data de carga');
                                const datosCarga =
                                    dataCargas[carga.id - 1].idCarga;
                                if (datosCarga) {
                                    carga.nombres = datosCarga.nombres;
                                    carga.primer_apellido =
                                        datosCarga.primerApellido;
                                    carga.segundo_apellido =
                                        datosCarga.segundoApellido;
                                    carga.email = datosCarga.email;
                                    carga.email_confirmacion = datosCarga.email;
                                    carga.genero = datosCarga.idGenero;
                                    carga.contacto = datosCarga.celular;
                                    carga.telefono = datosCarga.telefono;

                                    carga.parentesco = dataCargas[carga.id - 1]
                                        .idParentesco
                                        ? dataCargas[carga.id - 1].idParentesco
                                              .idParentesco
                                        : null;

                                    carga.fechaNacimiento =
                                        datosCarga.fechaNacimiento
                                            ? Moment(
                                                  datosCarga.fechaNacimiento
                                              ).format("YYYY-MM-DD")
                                            : "";
                                    carga.rut = clearRut(datosCarga.rut);
                                    carga.idBeneCarga =
                                        datosCarga.idBeneficiario;
                                    carga.esCargaCompleta =
                                        dataCargas[carga.id - 1].cargaCompleta;
                                    carga.cargaCompleta =
                                        dataCargas[carga.id - 1].cargaCompleta;
                                    setValue(
                                        "nombres_" + carga.id,
                                        datosCarga.nombres
                                    );
                                    setValue(
                                        "primer_apellido_" + carga.id,
                                        datosCarga.primerApellido
                                    );
                                    setValue(
                                        "segundo_apellido_" + carga.id,
                                        datosCarga.segundoApellido
                                    );
                                    setValue(
                                        "email_" + carga.id,
                                        datosCarga.email
                                    );
                                    setValue(
                                        "email_confirmacion_" + carga.id,
                                        datosCarga.email
                                    );
                                    setValue(
                                        "genero_" + carga.id,
                                        datosCarga.idGenero
                                    );
                                    setValue(
                                        "contacto_" + carga.id,
                                        datosCarga.celular
                                    );
                                    setValue(
                                        "telefono_" + carga.id,
                                        datosCarga.telefono
                                    );
                                    setValue(
                                        "parentesco_" + carga.id,
                                        dataCargas[carga.id - 1].idParentesco
                                            ? dataCargas[carga.id - 1]
                                                  .idParentesco.idParentesco
                                            : null
                                    );
                                    setValue(
                                        "fechaNacimiento_" + carga.id,
                                        datosCarga.fechaNacimiento
                                            ? Moment(
                                                  datosCarga.fechaNacimiento
                                              ).format("YYYY-MM-DD")
                                            : ""
                                    );

                                    setValue(
                                        "rut_" + carga.id,
                                        clearRut(datosCarga.rut)
                                    );
                                }
                            });
                        }
                    }
                }
            }
        }
        asignarValoresEdit();
    }, [dataBene, cargas, asignarcionCompletada, setAsignarcionCompletada]);

    let listGenero = generos.map((g) => (
        <option key={g.idGenero} value={g.idGenero}>
            {g.nombre}
        </option>
    ));
    let listParentesco = parentescos.map((p) => (
        <option key={p.idParentesco} value={p.idParentesco}>
            {p.descripcion}
        </option>
    ));

    //
    let listCargas = cargas.map((c) => (
        <CargasEdit
            key={c.id}
            id={c.id}
            register={register}
            errors={errors}
            listGenero={listGenero}
            listParentesco={listParentesco}
            reglaEdadMin={reglaEdadMin}
            reglaEdadMax={reglaEdadMax}
            cargas={cargas}
            setCargas={setCargas}
            datosBeneficiarioTitular={datos}
            clearErrors={clearErrors}
            datos={datos}
            ventaRemota={ventaRemota}
            btnDisabled={btnDisabled}
            dataVentaRemota={
                dataBene != []
                    ? dataCargas[c.id - 1]
                        ? dataCargas[c.id - 1].idCarga
                        : undefined
                    : null
            }
            edicionDatosRemota={edicionDatosRemota}
            clearRut={clearRut}
            infoCarga={c}
            cargaCompleta={dataCargas[c.id - 1].cargaCompleta}
        />
    ));

    let addSaleFormExtended = formExtended.map((c) => (
        <SalesFormExtendedEdit
            key={c.id}
            id={c.id}
            register={register}
            errors={errors}
            listGenero={listGenero}
            listParentesco={listParentesco}
            reglaEdadMin={reglaEdadMin}
            reglaEdadMax={reglaEdadMax}
            cargas={cargas}
            setCargas={setCargas}
            datosBeneficiarioTitular={datos}
            clearErrors={clearErrors}
            datos={datos}
            HandleInputChange={HandleInputChange}
            listCargas={listCargas}
            desdeAgregarCargas={true}
        />
    ));
    return (
        <div className="">
            <Card sx={{ minWidth: 275, padding: 1 }}>
                <ListItemText primary="Datos de cargas" />
                <Divider light />
                <form
                    onSubmit={handleSubmit(onSubmitDatosBeneficario)}
                    autoComplete="false"
                    className="formularioSale"
                >
                    <div className="col-md-12 p-3">{addSaleFormExtended}</div>

                    <div className="col-md-12 btn-acciones">
                        {!formExtended.length > 0 ? (
                            <Box
                                sx={{
                                    "& > :not(style)": { m: 1 },
                                }}
                                noValidate
                                autoComplete="off"
                            >
                                <Button
                                    type="button"
                                    variant="contained"
                                    onClick={continuarFormulario}
                                >
                                    Mostrar formulario completo
                                </Button>
                            </Box>
                        ) : (
                            ""
                        )}
                        <Box
                            sx={{
                                "& > :not(style)": { m: 1 },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Button
                                type="submit"
                                variant="contained"
                                color="success"
                            >
                                {textButton}
                            </Button>
                        </Box>
                    </div>
                </form>
            </Card>
        </div>
    );
};
