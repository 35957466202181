import React from 'react'

export const InputDate = ({register, descripcion, rules, type, placeholder, name, errors, onChange, value, requerido }) => {
    return <>
    <label htmlFor={name}><span className='dot'>•</span>{descripcion}{requerido ? <span className='requerido'> *</span> : ''}</label>
    <input
        {...register(name, rules)}
        type={type}
        placeholder={placeholder}
        className={`form-control ${errors && "form-error"}`}
        name={name}
        onChange={onChange}
        value={value}
    ></input>
    { errors && <small className="text-danger-seguro">{errors.message}</small> }
  </>
}
