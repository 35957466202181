import React from "react";
import { InputText } from "./InputText";
import { useDirectus } from "../../Providers/DirectusProvider";

export const InputEmail = ({
    disabled,
    register,
    name,
    errors,
    onChange,
    datos = { idBeneficiario: null },
    confirmar = false,
    clearErrors,
    onPaste,
    onCopy,
    idCarga = false,
    descripcion,
    cargas,
    carga = false,
    value,
    ventaRemota = false,
    validarNuevaCarga = true,
    checkingDisabled = false,
    desdeAgregarCargas = false,
    cargaCompleta = false,
    required = false,
    desdeCotizacion = false
}) => {
    const { directus } = useDirectus();

    const checkEmail = async (emailIngresado) => {
        //lo pasamos a endpoint por seguridad
        if (
            !desdeCotizacion &&
            ((!checkingDisabled || !ventaRemota) && validarNuevaCarga) ||
            desdeAgregarCargas
        ) {
            if (
                emailIngresado != "" &&
                emailIngresado &&
                !cargaCompleta &&
                !disabled
            ) {
                console.log("CONSULTADO", emailIngresado);
                console.log("cargaCompleta", cargaCompleta);
                var consulta = await directus.transport.get(
                    "/flow/check_email",
                    {
                        params: {
                            email: emailIngresado,
                            idBeneficiario: carga
                                ? desdeAgregarCargas
                                    ? null
                                    : cargas[idCarga - 1].idBeneCarga
                                : datos.idBeneficiario,
                        },
                    }
                );
                return consulta.raw;
            }
            return true;
        }
        return true;
    };

    const validarIgualdadEmail = (email, idCarga, confirmar) => {
            var emailValidar = null;
            var nombreEmailComparado = null;

            emailValidar = datos.email_confirmacion;
            nombreEmailComparado = "email";
            if (confirmar) {
                emailValidar = datos.email;
            }
            if (confirmar && email == emailValidar) {
                clearErrors(nombreEmailComparado);
            }
            if(email === ""){
                return true;
            }else{
                return email == emailValidar;
            }
            
        
    };


    const lowerCase = (e) => {
        e.target.value = e.target.value.toLowerCase();
        if (onChange) {
            onChange(e);
        }
    };

    return (
        <InputText
            disabled={disabled}
            register={register}
            descripcion={descripcion}
            rules={{
                /*required: "Campo obligatorio",*/
                pattern: {
                    value: /(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                    message: "Debe ingresar un correo electrónico válido",
                },                
                validate: {
                    validate: (v) =>
                        validarIgualdadEmail(v, idCarga, confirmar) ||
                        "Email no coincide",
                    emailExist: async (v) =>
                        (await checkEmail(v)) || "Ya se encuentra registrado",
                },
            }}
            type="text"
            placeholder="Ingrese email"
            name={name}
            errors={errors[name]}
            onChange={lowerCase}
            requerido={true}
            onPaste={onPaste}
            onCopy={onCopy}
            value={value}
        />
    );
};
