import { Form, useOutletContext } from "react-router-dom";
import "./IndexPage.css";
import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

const rutCompleto = (rut) => {
	let rutLimpio = rut?.split("@")[0].replace(/[^(0-9|k)]/gi, '');
	let M = 0, S = 1;
	let T = rutLimpio;
	for (; T; T = Math.floor(T / 10))
		S = (S + T % 10 * (9 - M++ % 6)) % 11;
	var dv = S ? S - 1 : 'k';
	return rutLimpio + '-' + dv;

};


export const IndexPage = (props) => {
  const dataPage = useOutletContext();
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
          Información
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {dataPage.first_name+" "+dataPage.last_name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
        {rutCompleto(dataPage.email)}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small">Editar Datos</Button>
        <Button size="small">Cambiar clave</Button>
      </CardActions>
    </Card>
  );
};
