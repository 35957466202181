import React, { useEffect, useState } from "react";
import "./IndexBeneficiario.css";
import { ListBeneficiario } from "./ListBeneficiario.js";
import { AddBeneficiario } from "./AddBeneficiario.js";

import { useDirectus } from "../../Providers/DirectusProvider";

export const IndexBeneficiario = (props) => {
    const { directus } = useDirectus();
    const [idClientesEmpresas, setIdClientesEmpresas] = useState([]);
    const [idSeguro, setIdSeguro] = useState([]);
    useEffect(() => {
        async function getClienteEmpresa() {
            let clienteEmpresa = [];
            let idInterno = null;
            try {
                const res = await directus.users.me
                    .read({
                        fields: ["*.*"],
                    })
                    .then(async (response) => {
                        console.log(response.id);
                        const vendedor = await directus
                            .items("vendedor")
                            .readByQuery({
                                fields: ["*.*"],
                                filter: {
                                    idUsuario: response.id,
                                },
                            })
                            .then(async (responseSell) => {
                                const segurosUsuario = await directus
                                    .items("vendedor_admin_seguro")
                                    .readByQuery({
                                        fields: ["*.*"],
                                        filter: {
                                            idVendedor:
                                                responseSell.data[0].idVendedor,
                                        },
                                    })
                                    .then(async (responseSeguro) => {
                                        // Utilizar map para recorrer la data y extraer los nombreDescriptivo
                                        const nombresDescriptivos =
                                            responseSeguro.data.map(
                                                (item) =>
                                                    item.idSeguro
                                                        .nombreDescriptivo
                                            );

                                        const idSeguro =
                                        responseSeguro.data.map(
                                            (item) =>
                                                item.idSeguro
                                                    .idSeguro
                                        );
                                        setIdSeguro(idSeguro); 

                                        idInterno = nombresDescriptivos;
                                        clienteEmpresa =
                                            await directus.transport.get(
                                                "/crearBeneficiario/clienteEmpresa/get",
                                                {
                                                    params: { idInterno },
                                                }
                                            );
                                            const datosConvertidos = JSON.parse(clienteEmpresa.data);
                                            const datosTransformados = datosConvertidos.map((item) => ({
                                                id: item.id,
                                                nombre_convenio: item.nombre_convenio,
                                            }));
                                        setIdClientesEmpresas(datosTransformados); //ejemplo de formato [10,45,55]
                                    });
                            });
                    });
            } catch (error) {
                console.log(error);
            }
        }
        getClienteEmpresa();
    }, [directus]);
    return (
        <div className="container-page">
            <AddBeneficiario idClientesEmpresas={idClientesEmpresas} idSeguro={idSeguro}/>
            <ListBeneficiario idClientesEmpresas={idClientesEmpresas} idSeguro={idSeguro} />
        </div>
    );
};
