import { Directus } from "@directus/sdk";
import { Navigate } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";

export const ctx = React.createContext();
export const DirectusProvider = ({ url, children }) => {
  const directus = useMemo(() => {
    return new Directus(url);
  }, [url]);
  const [autenticado, setAutenticado] = useState(false);
  const urlAssets = (idAsset) => {
    return new URL(`assets/${idAsset}`, url).href;
  };

  const IniciarSesion = async (rut, contrasena) => {
    // let usuario = contrasena ? `${rut}@adm.empresas.mhaite.com` : `${rut}@empresas.mhaite.com`;
    let usuario = `${rut}@ventas-seguros.cl`;

    try {
      await directus.auth.login({
        email: usuario,
        password: contrasena,
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Inicio de sesión fallido",
        text: "Rut o clave incorrecta"
      });

      console.error(error);
      setAutenticado(false);

      return false;
    }

    setAutenticado(true);
    Swal.fire({
      title: "Inicio de sesión exitoso",
      icon: "success"
    });
    return true;
  };

  const LogOutDire = async () => {
    await directus.auth.logout();
  };

  //esto sólo lo ocupamos para revisar, al cargar la página, si el token que tengo expiró o no
  useEffect(() => {
    try {
      if(directus.auth.token){
        setAutenticado(false);
      }
      if (!directus.auth.token) return;
  
      async function refrescarToken() {
        try {
          await directus.auth.refresh();
        } catch (exception) {
          //NAVEGACIÓN A RUTA LOGIN
          console.error("error al refrescar el token", exception);
          setAutenticado(false);
          return <Navigate to="/" />;
        }
  
        setAutenticado(true);
      }
  
      refrescarToken();
    } catch (exception) {
      //NAVEGACIÓN A RUTA LOGIN
      console.error("error al refrescar el token", exception);
      setAutenticado(false);
      return <Navigate to="/" />;
    }

  }, [directus, setAutenticado]);

  const value = {
    directus: directus,
    autenticado: autenticado,
    urlAssets: urlAssets,
    IniciarSesion: IniciarSesion,
    LogOutDire:LogOutDire
  };
  return <ctx.Provider value={value}> {children} </ctx.Provider>;
};

/**
 * @typedef {object} Contexto
 * @property {Directus<TypeMap>} directus
 * @property {boolean} autenticado
 * @property {function} iniciarSesion
 * @property {function} urlAssets
 * @property {function} LogOut
 * @property {PartialItem<UserItem>} usuario
 *
 * @returns {Contexto}
 * */
export const useDirectus = () => React.useContext(ctx);
