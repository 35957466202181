import { createContext, useContext, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { useDirectus } from "../Providers/DirectusProvider";

const AuthContext = createContext();

export const AuthProvider = ({ children, userData }) => {
    const [user, setUser] = useLocalStorage("user", userData);
    const [rolUsuario, setRolUsuario] = useLocalStorage("rol", "");
    const navigate = useNavigate();
    const { LogOutDire, directus } = useDirectus();

    const login = async (data) => {
        setUser(data);
        let rolUser = null;
        try {
            rolUser = await rol();
        } catch (error) {
            console.error("Error al obtener el rol:", error);
        }

        if (rolUser === "admin_beneficiarios") {
            navigate("/admin-beneficiarios", { replace: true });
        } else {
            navigate("/parorama-actual", { replace: true });
        }
    };

    const rol = async () => {
        const response = await directus.users.me.read({
            fields: ["*.*"],
        });
        setRolUsuario(response.role.name);
        return response.role.name;
    };

    const logout = async () => {
        LogOutDire();
        setUser(null);
        navigate("/", { replace: true });
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            rolUsuario,
        }),
        [user]
    );
    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
