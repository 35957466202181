import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { BrowserRouter as Router, Route } from "react-router-dom";
import {
    createBrowserRouter,
    createRoutesFromElements,
    defer,
} from "react-router-dom";
import { AuthLayout } from "./Components/AuthLayout";
import { Login } from "./Components/Login/Login";
import { IndexPage } from "./Pages/IndexPage";
import { PanoramaActual } from "./Pages/PanoramaActual/PanoramaActual";
import { AsistenteVentas } from "./Pages/Sales/AsistenteVenta";
import { VentasCompletas } from "./Pages/Sales/VentasCompletas.js";
import { NewSale } from "./Pages/Sales/NewSale";
import { NewQuote } from "./Pages/Sales/NewQuote";
import { InfoSale } from "./Pages/Sales/InfoSale";
import { MhaiteSeguros } from "./Pages/Sales/MhaiteSeguros";
import { IndexBeneficiario } from "./Pages/AdminBeneficiario/IndexBeneficiario.js";
import { ProtectedLayout } from "./Components/ProtectedLayout";
import { HomeLayout } from "./Components/HomeLayout";
import ErrorPage from "./Components/ErrorPage";
import { EditSale } from "./Pages/Sales/EditSale";
import { AgregarCargas } from "./Pages/Sales/AgregarCargas";
import { Quote } from "./Pages/Sales/Quote";

const notificarCarga = () => {
    const event = new CustomEvent("componentes_cargados", {
        detail: window.componentes,
    });
    document.dispatchEvent(event);
};

const getUserData = () =>
    new Promise((resolve) =>
        setTimeout(() => {
            const user = window.localStorage.getItem("user");
            resolve(user);
        }, 3000)
    );

export const App = ({ componentes }) => {
    const [componentesCreados, setComponentesCreados] = useState({});

    useEffect(() => {
        document.addEventListener("agregar_componente", (ev) => {
            const { id, nombre, props } = ev.detail;
            setComponentesCreados((visibles) => {
                visibles[id] = {
                    id,
                    nombre,
                    props,
                    portal: null,
                };
                return { ...visibles };
            });
        });
    }, []);

    const elementos = useMemo(() => {
        const ret = [];
        for (let { nombre, portal, props, id } of Object.values(
            componentesCreados
        )) {
            if (portal) {
            } else {
                const domElement = document.getElementById(id);
                domElement.classList.add("App");
                portal = createPortal(
                    componentes[nombre](props || {}),
                    domElement
                );
                componentesCreados[id].portal = portal;
            }
            ret.push(<div key={`${id}-${nombre}`}>{portal}</div>);
        }
        return ret;
    }, [componentesCreados, componentes]);

    useEffect(() => {
        setTimeout(notificarCarga, 1);
    }, []);
    return (
        <Router>
            <div id="app">{elementos}</div>;
        </Router>
    );
};

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<AuthLayout />}
            loader={() => defer({ userPromise: getUserData() })}
        >
            <Route element={<HomeLayout />}>
                <Route
                    path="/"
                    element={<Login />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="/login"
                    element={<Login />}
                    errorElement={<ErrorPage />}
                />
            </Route>

            <Route path="/" element={<ProtectedLayout />}>
                <Route
                    path="index-page"
                    element={<IndexPage tittle={"Bienvenido "} />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="parorama-actual"
                    element={<PanoramaActual tittle={"Panorama Actual"} />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="asistente-ventas"
                    element={<AsistenteVentas tittle={"Asistente de Ventas"} />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="ventas-completas"
                    element={
                        <VentasCompletas
                            tittle={"Listado de ventas completadas"}
                        />
                    }
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="quote"
                    element={<Quote tittle={"Listado de Cotizaciones"} />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="nueva-venta"
                    element={<NewSale tittle={"Nueva Venta Remota"} />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="nueva-cotizacion"
                    element={<NewQuote tittle={"Nueva Cotización"} />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="editar-venta"
                    element={<EditSale tittle={"Editar Venta"} />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="agregar-cargas"
                    element={<AgregarCargas tittle={"Agregar cargas"} />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="informes-venta"
                    element={<InfoSale tittle={"Informes de ventas"} />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="mhaite-seguros"
                    element={<MhaiteSeguros tittle={"Mhaite Seguros"} />}
                    errorElement={<ErrorPage />}
                />
                <Route
                    path="admin-beneficiarios"
                    element={<IndexBeneficiario tittle={"Mantenedor beneficiarios"} />}
                    errorElement={<ErrorPage />}
                />
            </Route>
        </Route>
    )
);
