import { useState } from "react";
import { Form } from "react-bootstrap";

export const RutInput = ({ onChange, onIsValid, placeholder }) => {
  const [rut, setRut] = useState("");
  const [valido, setValido] = useState(false);
  const calculoDV = (T) => {
    let M = 0,
      S = 1;
    for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
    return S ? S - 1 : "k";
  };
  const revisaValido = (rut, dv) => {
    const valido = calculoDV(rut).toString().toLowerCase() === dv.toLowerCase();
    setValido(valido);
    if (onIsValid) {
      onIsValid(valido);
    }
  };
  const setUsuarioConFormato = (value) => {
    const limpio = value.replace(/[^(0-9|k)]/gi, "");
    if (limpio.length <= 1) {
      onChange(limpio);
      return setRut(limpio);
    }
    let dv = limpio[limpio.length - 1];
    let rut = limpio.slice(0, -1);
    setRut(`${rut}-${dv}`);
    onChange(rut);
    revisaValido(rut, dv);
  };

  return (
    <Form.Control
      size="sm"
      value={rut}
      onChange={(event) => setUsuarioConFormato(event.target.value)}
      placeholder={placeholder}
      maxLength={10}
    />
  );
};
